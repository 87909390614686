export const cartListTypes = {
  GET_PRODUCT_LIST: "GET_PRODUCT_LIST",
  GET_PRODUCT_LIST_SUCCESS: "GET_PRODUCT_LIST_SUCCESS",
  GET_PRODUCT_LIST_ERROR: "GET_PRODUCT_LIST_ERROR",
  GET_CART_LIST: "GET_CART_LIST",
  GET_CART_LIST_SUCCESS: "GET_CART_LIST_SUCCESS",
  GET_CART_LIST_ERROR: "GET_CART_LIST_ERROR",
  GET_CART_LIST_ITEMS: "GET_CART_LIST_ITEMS",
  GET_CART_LIST_ITEMS_SUCCESS: "GET_CART_LIST_ITEMS_SUCCESS",
  GET_CART_LIST_ITEMS_ERROR: "GET_CART_LIST_ITEMS_ERROR",
  ADD_CART_LIST_ITEMS: "ADD_CART_LIST_ITEMS",
  ADD_CART_LIST_ITEMS_SUCCESS: "ADD_CART_LIST_ITEMS_SUCCESS",
  ADD_CART_LIST_ITEMS_ERROR: "ADD_CART_LIST_ITEMS_ERROR",
  DELETE_CART_LIST_ITEMS: "DELETE_CART_LIST_ITEMS",
  DELETE_CART_LIST_ITEMS_SUCCESS: "DELETE_CART_LIST_ITEMS_SUCCESS",
  DELETE_CART_LIST_ITEMS_ERROR: "DELETE_CART_LIST_ITEMS_ERROR"
};

export default cartListTypes;
