import React from "react";
import Styles from "./userName.module.css";

const UserName = props => (
  <div className={Styles.userFullname}>
    Hello
    <span style={{ margin: "5px" }}>{props.data.firstName}</span>
    {props.data.lastName},
  </div>
);

export default UserName;
