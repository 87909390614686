import React from "react";
import Layout from "../../components/layout";
import PropTypes from "prop-types";
import "./myorders.css";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import { Table, Divider, Tag } from "antd";
import { getMyOrder } from "./myOrderAction";
import Valimg from "../../img/valves_ball.jpeg";
const columns = [
  {
    title: "Order #",
    dataIndex: "order",
    key: "order"
  },
  {
    title: "Order Date",
    dataIndex: "date",
    key: "date"
  },
  {
    title: "Order Status",
    key: "tags",
    dataIndex: "tags",
    render: tags => (
      <span>
        {tags.map(tag => {
          let color = " ";
          if (tag === "New") {
            color = "badgetagpro";
          } else if (tag == "Processing") {
            color = "badgetagcom";
          } else if (tag == "Cancelled") {
            color = "badgetagcnl";
          }
          return (
            // <Tag color={color} key={tag} background={backcolor}>
            //   {tag.toUpperCase()}
            // </Tag>
            <div className={color}>
              <span className="bagdetagtext">{tag.toUpperCase()}</span>
            </div>
          );
        })}
      </span>
    )
  },
  {
    title: "Delivery/Shipping Address",
    dataIndex: "shipto",
    key: "shipto"
  }
];

// const data = this.props.orderDetails.result.details.content.orderDetails.map((item, index) => [
//   {
//     key: item.orderId
//   }
// ]);

class MyOrders extends React.Component {
  UserOrderDetails = unorderArray => {
    console.log(unorderArray, "under array");
    const tabledata = unorderArray.map((item, index) => [
      {
        key: index + 1,
        order: item.orderDetails.orderCode,
        date: item.orderDetails.orderDate,
        tags: [item.orderDetails.orderStatus],
        shipto:
          item.deliveryDetails.deliveryAddress.deliveryAddress +
          ", " +
          "Buliding " +
          item.deliveryDetails.deliveryAddress.siteDetail.buildingNumber +
          ", Floor " +
          item.deliveryDetails.deliveryAddress.siteDetail.floorNumber +
          ", Zone " +
          item.deliveryDetails.deliveryAddress.siteDetail.zone,
        description: "Unit/Qty: 516",
        desimg: "../../img/meh_logo.png"
      }
    ]);

    const extractArrays = data => {
      return Object.values(data)
        .filter(obj => Array.isArray(obj))
        .flat(1);
    };
    let data = extractArrays(tabledata);

    return data;
  };

  componentDidMount = () => {
    const { userInfo } = this.props;
    if (userInfo.result !== null) {
      const userId = userInfo.result.userId;
      console.log("UserId", userInfo.result.userId);
      this.props.userMyOrder(userId);
    }
  };

  componentWillUpdate() {
    console.log(this.props.orderDetails.result);
  }
  render() {
    return (
      <Layout {...this.props}>
        <div className="tophead">
          <h1 classs="titlehtwo">My Order</h1>
        </div>
        <div className="tablecontainer">
          {this.props.orderDetails &&
          this.props.orderDetails.result &&
          this.props.orderDetails.result.details.content.length !== 0 ? (
            <Row>
              <Col>
                <Table
                  columns={columns}
                  expandedRowRender={record => (
                    <div className="descdetail">
                      <ul style={{ float: "left" }}>
                        <li>
                          <div className="moreitems">
                            <img src={Valimg} width="80px;"></img>
                            <small>{record.description}</small>
                          </div>
                        </li>
                        <li>
                          <div className="moreitems">
                            <img src={Valimg} width="80px;"></img>
                            <small>{record.description}</small>
                          </div>
                        </li>
                        <li>
                          <div className="moreitems">
                            <img src={Valimg} width="80px;"></img>
                            <small>{record.description}</small>
                          </div>
                        </li>
                      </ul>
                    </div>
                  )}
                  dataSource={this.UserOrderDetails(this.props.orderDetails.result.details.content)}
                  pagination={false}
                  className="ordertable"
                />
              </Col>
            </Row>
          ) : (
            "Loading"
          )}
        </div>
      </Layout>
    );
  }
}

MyOrders.propTypes = {
  orderDetails: PropTypes.shape({
    result: PropTypes.object
  }).isRequired,
  userMyOrder: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  userInfo: state.user.info,
  orderDetails: state.myOrderDetails.myOrder
});

const mapDispatchToProps = dispatch => ({
  userMyOrder: userId => dispatch(getMyOrder(userId))
});

export default connect(mapStateToProps, mapDispatchToProps)(MyOrders);
