import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { Form, Input, Button, Row, Col, message } from "antd";

import { getUserAction } from "./forgetAction";
import Footer from "../../components/footer";
import MainLogo from "../login/mainLogo";

import Styles from "./forget.module.css";

class ForgetPassword extends Component {
  state = {
    userName: ""
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 16 },
        lg: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
        lg: { span: 24 }
      }
    };
    return (
      <div>
        <Row>
          <Col lg={24}>
            <MainLogo />
          </Col>
        </Row>
        <Row className={Styles.mehlogin}>
          <Col lg={24}>
            <div className={Styles.form}>
              <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                <div className={Styles.formName}>
                  <span>Forget Password</span>
                </div>
                <Form.Item className={Styles.formInput}>
                  <label className={Styles.labeling}>Enter your E-Mail</label>
                  {getFieldDecorator("userName", {
                    rules: [{ required: true, message: "Please input your Email Address" }]
                  })(
                    <Input
                      size="large"
                      name="userName"
                      onChange={this.handleUsernameChange}
                    ></Input>
                  )}
                </Form.Item>
                <Form.Item className={Styles.submit}>
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    className={Styles.loginFormButton}
                  >
                    {this.props.userData.result === "Password reset successfully"
                      ? (message.success("Password reset successfully"),
                        (<Redirect to={"./login"}></Redirect>))
                      : ""}
                    {this.props.userData.result === "failed:emailExist"
                      ? message.error("Email Does Not Exists")
                      : ""}
                    RESET PASSWORD
                  </Button>{" "}
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={24}>
            <div className={Styles.footer}>{<Footer {...this.props} />}</div>
          </Col>
        </Row>
      </div>
    );
  }

  handleUsernameChange = e => {
    this.setState({ userName: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      const resetUser = { userName: this.state.userName };

      this.props.resetUser(resetUser);
    });
  };
}

ForgetPassword.propTypes = {
  userData: PropTypes.shape({
    result: PropTypes.string
  }).isRequired,
  resetUser: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  userData: state.reset.forget
});

const mapDispatchToProps = dispatch => ({
  resetUser: resetUser => dispatch(getUserAction(resetUser))
});

const ForgetPass = Form.create({ name: "normal_login" })(ForgetPassword);
export default connect(mapStateToProps, mapDispatchToProps)(ForgetPass);
