import React from "react";
import Styles from "./header.module.css";
import UserInfo from "../userinfo";
import { Icon, Dropdown, Menu } from "antd";

const menu = (
  <Menu>
    <Menu.Item>
      <a href="./logout">Logout</a>
    </Menu.Item>
  </Menu>
);
class Header extends React.Component {
  render() {
    return (
      <div>
        <header className={Styles.header}>
          <ul className={Styles.headerlist}>
            <li className={Styles.dropdown}>
              <a href="#">
                {this.props.userInfo.result ? <UserInfo data={this.props.userInfo.result} /> : null}
              </a>
              <div className={Styles.arrow_box}>
                <a href="./logout">Logout</a>
              </div>
            </li>

            <li className={Styles.headerlistitem}>
              <a className={Styles.help} href="#">
                Help
              </a>
            </li>

            <li className={Styles.headerlistitem}>
              <Icon className={Styles.questionCircle} type="question-circle" />
            </li>
            <li className={Styles.headerlistitem}>
              <Icon className={Styles.bell} type="bell" />
            </li>
          </ul>
        </header>
      </div>
    );
  }
}

export default Header;
