import React from "react";
import Styles from "./footer.module.css";

function Footer() {
  return (
    <div className={Styles.footer}>
      <p>© SGN Engineer Tool, 2024</p>
    </div>
  );
}
export default Footer;
