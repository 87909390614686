import React, { Component } from "react";
import { Row, Col } from "antd";
import Footer from "../../components/footer";
import LoginForm from "./loginForm";
import MainLogo from "./mainLogo";
import Styles from "./login.module.css";

class Login extends Component {
  render() {
    return (
      <div>
        <Row>
          <Col lg={24}>
            <MainLogo {...this.props} />
          </Col>
        </Row>
        <Row className={Styles.mehlogin}>
          <Col lg={24}>
            <div className={Styles.loginarea}>
              <LoginForm {...this.props} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={24}>
            <div className={Styles.footer}>
              <Footer {...this.props} />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Login;
