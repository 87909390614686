import React from "react";
import { Provider } from "react-redux";

import { ConnectedRouter } from "connected-react-router";
import Routes from "./routes";
import { history, store } from "./store";

// Main app bootstrap.
const App = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>{Routes(store)}</ConnectedRouter>
  </Provider>
);

export default App;
