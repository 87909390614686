import React, { Component } from "react";
import { connect } from "react-redux";
import { debounce } from "lodash";
import { Row, Col, Button, Spin, Steps, Popover, Card, Pagination } from "antd";

import ErrorHandler from "../error-handling/error-handler";
import CartItem from "../order-now/cart-item/cart-item";
import Layout from "../../components/layout";

import {
  addCartListItemsAction,
  deleteCartListItemsAction,
  getCartSavedListAction
} from "../order-now/orderNowAction";

import "../order-now/orderNow.css";
import "./saved-cart-items.css";

class SavedCartItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enableDelete: true,
      currentPage: 1,
      pageSize: 5,
      enableSpinner: false,
      currentItem: null
    };
    this.onShowSizeChange = this.onShowSizeChange.bind(this);
    this.addItem = debounce(this.addItem, 1000);
  }

  // componentDidMount() {
  //   const { getCartSavedListData, userDetails, cartId } = this.props;
  //   if (this.props.userDetails.result && cartId) {
  //     getCartSavedListData(userDetails.result, this.state.currentPage, this.state.pageSize, cartId);
  //   }
  // }

  componentDidUpdate(prevProps) {
    const { getCartSavedListData, userDetails, cartId } = this.props;
    if (prevProps.userDetails.result !== this.props.userDetails.result) {
      if (this.props.userDetails.result && this.props.userDetails.result.userId && cartId) {
        getCartSavedListData(
          userDetails.result,
          this.state.currentPage,
          this.state.pageSize,
          cartId
        );
      }
    }
    if (
      prevProps.cartDetails.deleteCartItem.result !==
        this.props.cartDetails.deleteCartItem.result &&
      this.props.cartDetails.deleteCartItem.result
    ) {
      getCartSavedListData(userDetails.result, this.state.currentPage, this.state.pageSize, cartId);
    }
  }
  onShowSizeChange(currentPage, pageSize) {
    const { userDetails, getCartSavedListData } = this.props;
    this.setState({ currentPage: currentPage }, () =>
      getCartSavedListData(userDetails.result, currentPage, pageSize)
    );
  }
  addItem(user, item, quantity, cartId) {
    this.props.addItemToCart(user, item, quantity, cartId);
    this.setState({ enableSpinner: !this.state.enableSpinner, currentItem: item });
    setTimeout(() => {
      this.setState({ enableSpinner: !this.state.enableSpinner, currentItem: null });
    }, 1000);
  }
  render() {
    const { cartDetails, deleteItemFromCart, userDetails, cartId } = this.props;
    const { cartSavedItemList } = cartDetails;
    const { Step } = Steps;

    return (
      <Layout {...this.props}>
        <div className="saved_cart_page_container">
          <Row>
            <Col span={24}>
              {" "}
              <div className="steps">
                <Steps progressDot current={0}>
                  <Step title="Your Orders" />
                  <Step title="Delivery Channel" />
                  <Step title="Order Summaries" />
                  <Step title="Order Confirmation" />
                </Steps>
              </div>
            </Col>
          </Row>
          {cartSavedItemList.fetching ? (
            <Spin className="spinner" size="large" tip="Loading..." />
          ) : (
            <>
              {/* <div style={{ background: "#fff", margin: "0px" }}> */}
              <div className="savecartcontainer">
                <Row>
                  <div>
                    <h1 className="yourorder">Your Orders</h1>
                  </div>
                  <Col span={24}>
                    {cartSavedItemList.result &&
                    cartSavedItemList.result.content.userCartItemDetails.length !== 0 ? (
                      cartSavedItemList.result.content.userCartItemDetails.map((item, index) => (
                        <div key={index} className="card_container">
                          <CartItem
                            cartItem={item}
                            deleteIcon={this.state.enableDelete}
                            addItemToCart={(item, quantity) => {
                              this.addItem(userDetails.result, item, quantity, cartId);
                            }}
                            deleteItemFromCart={item => {
                              deleteItemFromCart(userDetails.result, item, cartId);
                            }}
                            spinner={
                              this.state.currentItem === item.itemId
                                ? this.state.enableSpinner
                                : false
                            }
                          />
                        </div>
                      ))
                    ) : (
                      <ErrorHandler errorCase={"itemNotFound"} />
                    )}
                  </Col>
                </Row>
              </div>

              {cartSavedItemList.result &&
              cartSavedItemList.result.content.userCartItemDetails.length !== 0 ? (
                <>
                  <div className="bottomcontain">
                    <Row>
                      <Col span={8}>
                        <Pagination
                          onChange={this.onShowSizeChange}
                          defaultCurrent={this.state.currentPage}
                          total={cartSavedItemList.result.totalElements}
                          showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                          pageSize={this.state.pageSize}
                        />
                      </Col>
                      <Col span={8} />
                      <Col span={4}>
                        <Button
                          type="primary"
                          size="large"
                          ghost
                          onClick={() => this.props.history.push("/ordernow")}
                          className="add_more"
                        >
                          Add More
                        </Button>
                      </Col>
                      <Col span={4}>
                        <Button
                          type="primary"
                          size="large"
                          onClick={() => this.props.history.push("/delivery-channel")}
                        >
                          Begin Checkout
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </>
              ) : (
                <Row>
                  <Button
                    type="primary"
                    size="large"
                    ghost
                    onClick={() => this.props.history.push("/ordernow")}
                    className="add_more"
                  >
                    Go Back
                  </Button>
                </Row>
              )}
            </>
          )}
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  cartDetails: state.cart.cartDetails,
  userDetails: state.user.info,
  cartId: state.user.cartId.result
});

const mapDispatchToProps = dispatch => ({
  getCartSavedListData: (event, currentPage, pageSize, cartId) =>
    dispatch(
      getCartSavedListAction({
        userId: event ? event.userId : null,
        cartId: cartId ? cartId : Number(localStorage.getItem("cartId")),
        currentPage: currentPage,
        pageSize: pageSize
      })
    ),
  addItemToCart: (event, ItemId, quantity, cartId) =>
    dispatch(
      addCartListItemsAction({
        userId: event ? event.userId : null,
        cartId: cartId ? cartId : Number(localStorage.getItem("cartId")),
        quantity: quantity,
        itemId: ItemId
      })
    ),
  deleteItemFromCart: (event, ItemId, cartId) =>
    dispatch(
      deleteCartListItemsAction({
        userId: event ? event.userId : null,
        cartId: cartId ? cartId : Number(localStorage.getItem("cartId")),
        itemId: ItemId
      })
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(SavedCartItems);
