import { combineReducers } from "redux";

import * as actions from "./myOrderAction";

const initialState = { result: null, error: null };

const myOrder = (state = initialState, action) => {
  switch (action.type) {
    case actions.MY_ORDER:
      return { ...state };
    case actions.MY_ORDER_SUCCESS:
      return { result: action.result, error: null };
    case actions.MY_ORDER_ERROR:
      return { result: null, error: action.error };
    default:
      return { ...state };
  }
};

export default combineReducers({
  myOrder
});
