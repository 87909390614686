import { combineReducers } from "redux";

const initialActivities = { result: [], error: null, fetching: false };

const activities = (state = initialActivities, action) => {
  switch (action.type) {
    case "GET_ACTIVITIES":
      return { ...state, fetching: true };
    case "GET_ACTIVITIES_SUCCESS":
      return { result: action.result, error: null, fetching: false };
    case "GET_ACTIVITIES_ERROR":
      return { result: [], error: action.error, fetching: false };
    default:
      return state;
  }
};

export default combineReducers({
  activities
});
