import React from "react";
import Styles from "./userinfo.module.css";

const UserInfo = props => (
  <div className={Styles.user}>
    <div className={Styles.userFullname}>
      <p>
        <span style={{ margin: "5px" }}>{props.data.firstName}</span>
        {props.data.lastName}
      </p>
      <p className={Styles.userInitialsname}>{props.data.userInitails}</p>
    </div>
  </div>
);

export default UserInfo;
