import { cartListTypes } from "./orderNowTypes";

export const getCartProductListAction = payload => ({
  type: cartListTypes.GET_PRODUCT_LIST,
  payload
});
export const getCartSavedListAction = payload => ({
  type: cartListTypes.GET_CART_LIST,
  payload
});
export const getCartListItemsAction = payload => ({
  type: cartListTypes.GET_CART_LIST_ITEMS,
  payload
});
export const deleteCartListItemsAction = payload => ({
  type: cartListTypes.DELETE_CART_LIST_ITEMS,
  payload
});
export const addCartListItemsAction = payload => ({
  type: cartListTypes.ADD_CART_LIST_ITEMS,
  payload
});
