import axios from "axios";
import { call, put } from "redux-saga/effects";
import { showLoading, hideLoading } from "react-redux-loading-bar";

import * as actions from "./deliveryChannelAndAddressAction";
import config from "../../config/appConfig";

const callToAPI = async (method, url, data = {}) => {
  try {
    const response = await axios({
      method: method,
      url: url,
      data
    });
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export function* postCreateOrderWorker(payload) {
  try {
    yield put(showLoading());
    const url = `${config.apiBaseUrl}${config.createOrderEndpoint}`;
    const result = yield call(callToAPI, "POST", url, payload);
    yield put({
      result,
      error: null,
      type: actions.POST_CREATE_ORDER_SUCCESS
    });
  } catch (error) {
    yield put({
      result: null,
      error,
      type: actions.POST_CREATE_ORDER_ERROR
    });
  } finally {
    yield put(hideLoading());
  }
}
