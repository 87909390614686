import { all } from "redux-saga/effects";

import homeSaga from "./pages/home/homeSaga";
import cartListSaga from "./pages/order-now/orderNowSaga";
import LoginSaga from "./pages/login/loginSaga";
import forgetSaga from "./pages/forget-password/forgetSaga";
import orderSummarySaga from "./pages/order-summary/orderSummarySaga";
import deliveryChannelAndAddressSaga from "./pages/delivery-channel/deliveryChannelAndAddressSaga";
import myOrderSaga from "./pages/myorders/myOrderSaga";

export default function* rootSaga() {
  yield all(
    [
      ...homeSaga,
      ...LoginSaga,
      ...forgetSaga,
      ...cartListSaga,
      ...orderSummarySaga,
      ...deliveryChannelAndAddressSaga,
      ...myOrderSaga
    ].map(fn => fn())
  );
}
