import { combineReducers } from "redux";
import { cartListTypes } from "./orderNowTypes";
const INITIAL_STATE = {
  cartProductList: { result: null, error: null, fetching: false },
  cartSavedItemList: { result: null, error: null, fetching: false },
  cartItemList: { result: null, error: null, fetching: false },
  addCartItem: { result: null, error: null, fetching: false },
  deleteCartItem: { result: null, error: null, fetching: false }
};
const cartDetails = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case cartListTypes.GET_PRODUCT_LIST:
      return { ...state, cartProductList: { result: null, error: null, fetching: true } };
    case cartListTypes.GET_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        cartProductList: { result: action.result.data.content, error: null, fetching: false }
      };
    case cartListTypes.GET_PRODUCT_LIST_ERROR:
      return { ...state, cartProductList: { result: null, error: action.error, fetching: false } };
    case cartListTypes.GET_CART_LIST:
      return { ...state, cartSavedItemList: { result: null, error: null, fetching: true } };
    case cartListTypes.GET_CART_LIST_SUCCESS:
      return {
        ...state,
        cartSavedItemList: { result: action.result.data, error: null, fetching: false }
      };
    case cartListTypes.GET_CART_LIST_ERROR:
      return {
        ...state,
        cartSavedItemList: { result: null, error: action.error, fetching: false }
      };
    case cartListTypes.GET_CART_LIST_ITEMS:
      return { ...state, cartItemList: { result: null, error: null, fetching: true } };
    case cartListTypes.GET_CART_LIST_ITEMS_SUCCESS:
      return {
        ...state,
        cartItemList: { result: action.result.data, error: null, fetching: false }
      };
    case cartListTypes.GET_CART_LIST_ITEMS_ERROR:
      return { ...state, cartItemList: { result: null, error: action.error, fetching: false } };
    case cartListTypes.ADD_CART_LIST_ITEMS:
      return { ...state, addCartItem: { result: null, error: null, fetching: true } };
    case cartListTypes.ADD_CART_LIST_ITEMS_SUCCESS:
      return {
        ...state,
        addCartItem: { result: action.result.data, error: null, fetching: false }
      };
    case cartListTypes.ADD_CART_LIST_ITEMS_ERROR:
      return { ...state, addCartItem: { result: null, error: action.error, fetching: false } };
    case cartListTypes.DELETE_CART_LIST_ITEMS:
      return { ...state, deleteCartItem: { result: null, error: null, fetching: true } };
    case cartListTypes.DELETE_CART_LIST_ITEMS_SUCCESS:
      return {
        ...state,
        deleteCartItem: { result: action.result.data, error: null, fetching: false }
      };
    case cartListTypes.DELETE_CART_LIST_ITEMS_ERROR:
      return { ...state, deleteCartItem: { result: null, error: action.error, fetching: false } };
    default:
      return { ...state };
  }
};

export default combineReducers({
  cartDetails
});
