import { call, put } from "redux-saga/effects";
import axios from "axios";
import { showLoading, hideLoading } from "react-redux-loading-bar";

import * as actions from "./orderSummaryAction";
import config from "../../config/appConfig";

const callToAPI = async (method, url, data = {}) => {
  try {
    const response = await axios({
      method,
      url,
      data
    });

    const itemsDetails = response.data["itemsDetails"];
    const deliveryDetails = response.data["deliveryDetails"];
    const status = response.data;
    return { deliveryDetails, itemsDetails, status };
  } catch (error) {
    throw error;
  }
};

export function* getOrderSummaryWorker(payload) {
  if (payload !== "") {
    try {
      const size = 100;
      const page = 0;
      yield put(showLoading());

      const url = `${config.apiBaseUrl}${config.getOrderSummaryEndpoint}?orderId=${payload}&size=${size}&page=${page}`;
      const result = yield call(callToAPI, "GET", url);

      yield put({
        result,
        error: null,
        type: actions.GET_ORDER_SUMMARY_SUCCESS
      });
    } catch (error) {
      yield put({
        result: null,
        error: error,
        type: actions.GET_ORDER_SUMMARY_ERROR
      });
    } finally {
      yield put(hideLoading());
    }
  }
}

export function* updateOrderStatus(payload) {
  try {
    yield put(showLoading());

    const url = `${config.apiBaseUrl}${config.getOrderStatusLookupValues}`;
    const result = yield call(callToAPI, "GET", url);

    yield put({
      result,
      error: null,
      type: actions.POST_ORDER_STATUS_SUCCESS
    });
  } catch (error) {
    yield put({
      result: null,
      error: error,
      type: actions.POST_ORDER_STATUS_ERROR
    });
  } finally {
    yield put(hideLoading());
  }
}

export function* updateOrderStatusId(payload, statusId) {
  if (payload !== "" && statusId !== "") {
    try {
      yield put(showLoading());

      const url = `${config.apiBaseUrl}${config.updateOrderStatus}?orderId=${payload}&orderStatusId=${statusId}`;
      const result = yield call(callToAPI, "POST", url);

      yield put({
        result,
        error: null,
        type: actions.PRE_ORDER_STATUS_SUCCESS
      });
    } catch (error) {
      yield put({
        result: null,
        error: error,
        type: actions.PRE_ORDER_STATUS_ERROR
      });
    } finally {
      yield put(hideLoading());
    }
  }
}

export function* initiateCartWorker(payload) {
  try {
    yield put(showLoading());
    const url = `${config.apiBaseUrl}${config.initiateCartEndpoint}`;
    const result = yield call(callToAPI, "POST", url, payload);
    yield put({
      result,
      error: null,
      type: actions.POST_INITIATE_CART_SUCCESS
    });
  } catch (error) {
    yield put({
      result: null,
      error: error,
      type: actions.POST_INITIATE_CART_ERROR
    });
  } finally {
    yield put(hideLoading());
  }
}
