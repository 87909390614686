import { call, put } from "redux-saga/effects";
import axios from "axios";

import * as actions from "./forgetAction";
import config from "../../config/appConfig";

const authentication = async userName => {
  try {
    const response = await axios.post(
      `${config.apiBaseUrl}${config.forgotPasswordEndpoint}?emailId=${userName}`
    );

    return response.data.successMessage;
  } catch (error) {
    const errorMessage = error.response.data[0].message;
    return errorMessage;
  }
};

export default function* fetchDataWorker(payload) {
  if (payload) {
    try {
      const result = yield call(authentication, payload.userName);
      yield put({
        result,
        error: null,
        type: actions.RESET_PASSWORD_SUCCESS
      });
    } catch (error) {
      yield put({
        result: null,
        error: error,
        type: actions.RESET_PASSWORD_ERROR
      });
    }
  }
}
