import { takeLatest, call } from "redux-saga/effects";

import * as actions from "./loginAction";
import {
  postUserLoginWorker,
  getUserWorker,
  getUserAddressWorker,
  getUserBuildingWorker,
  getUserFloorWorker,
  getUserRoomWorker,
  getUserZoneWorker,
  initiateCartWorker
} from "./loginWorkers";

// watch for every purchase completion, and start the purchaseFlow
export function* watchPostUserLogin() {
  yield takeLatest(actions.POST_USER_LOGIN, function*(loadAction) {
    yield call(postUserLoginWorker, loadAction.payload);
  });
}

export function* watchGetUser() {
  yield takeLatest(actions.GET_USER, function*(loadAction) {
    yield call(getUserWorker, loadAction.payload);
  });
}

export function* watchGetUserAddress() {
  yield takeLatest(actions.GET_USER_ADDRESS, function*(loadAction) {
    yield call(getUserAddressWorker, loadAction.payload);
  });
}

export function* watchGetUserBuilding() {
  yield takeLatest(actions.GET_USER_BUILDING, function*(loadAction) {
    yield call(getUserBuildingWorker, loadAction.payload);
  });
}

export function* watchGetUserFloor() {
  yield takeLatest(actions.GET_USER_FLOOR, function*(loadAction) {
    yield call(getUserFloorWorker, loadAction.payload);
  });
}

export function* watchGetUserRoom() {
  yield takeLatest(actions.GET_USER_ROOM, function*(loadAction) {
    yield call(getUserRoomWorker, loadAction.payload);
  });
}

export function* watchGetUserZone() {
  yield takeLatest(actions.GET_USER_ZONE, function*(loadAction) {
    yield call(getUserZoneWorker, loadAction.payload);
  });
}

export function* watchInitiateCart() {
  yield takeLatest(actions.POST_INITIATE_CART, function*(loadAction) {
    yield call(initiateCartWorker, loadAction.payload);
  });
}
