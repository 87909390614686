import React from "react";
import { connect } from "react-redux";
import { debounce } from "lodash";
import moment from "moment";

import { Row, Col, Steps, Tabs, Popover, Button, Form, Select, Input, DatePicker } from "antd";

import Layout from "../../components/layout";
import Collectfromstore from "../../components/collectfromstore";
import CartItem from "../../pages/order-now/cart-item/cart-item";
import ErrorHandler from "../error-handling/error-handler";

import {
  getCartSavedListAction,
  addCartListItemsAction,
  deleteCartListItemsAction,
  getCartProductListAction
} from "../order-now/orderNowAction";

import {
  getUserBuildingAction,
  getUserfloorAction,
  getUserRoomAction,
  getUserZoneAction,
  resetUserFloorAction,
  resetUserRoomAction
} from "../../pages/login/loginAction";

import { createOrderAction } from "./deliveryChannelAndAddressAction";

import "./deliveryChannelAndAddress.css";
import "../order-now/cart-item/cart-item.css";
import Styles from "./deliveryaddress.module.css";

import onsitedelivery from "../../assets/onsitedelivery.svg";
import collectfromstore from "../../assets/collectfromstore.svg";

const { Step } = Steps;
const { TabPane } = Tabs;
const { Option } = Select;
const { TextArea } = Input;

const dateFormatList = ["DD-MM-YYYY"];

class DeliveryChannelAndAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enableSpinner: false,
      currentItem: null,
      buildingId: undefined,
      floorId: undefined,
      roomId: undefined,
      zoneId: undefined,
      date: undefined,
      deliveryInstruction: undefined
    };
    this.addItem = debounce(this.addItem, 1000);
  }
  componentDidMount = () => {
    const {
      building,
      getUserBuilding,
      zone,
      getUserZone,
      userDetails,
      getCartSavedListData,
      cartId
    } = this.props;

    if (building && building.result && building.result.length === 0) {
      getUserBuilding();
    }
    if (zone && zone.result && zone.result.length === 0) {
      getUserZone();
    }
    if (userDetails.result && userDetails.result.userId && cartId) {
      getCartSavedListData(userDetails.result, 1, 1000, cartId);
    }
  };

  componentDidUpdate = prevProps => {
    const { cartDetails, userDetails, getCartSavedListData, cartId, placeOrderInfo } = this.props;
    const { cartSavedItemList } = cartDetails;
    if (!cartSavedItemList.result && cartSavedItemList.result !== prevProps.cartDetails.result) {
      if (this.props.userDetails.result !== prevProps.userDetails.result) {
        if (this.props.userDetails.result && this.props.userDetails.result.userId && cartId) {
          getCartSavedListData(userDetails.result, 1, 1000, cartId);
        }
      }
    }
    if (
      prevProps.cartDetails.deleteCartItem.result !==
        this.props.cartDetails.deleteCartItem.result &&
      this.props.cartDetails.deleteCartItem.result
    ) {
      getCartSavedListData(userDetails.result, 1, 1000, cartId);
    }
    if (prevProps.placeOrderInfo.result !== placeOrderInfo.result) {
      this.props.history.push("/order-summary");
    }
  };
  handleSubmit = e => {
    e.preventDefault();
    const { form, userDetails, address, cartId, createOrder } = this.props;
    form.validateFields((err, values) => {
      const { buildingId, floorId, roomId, zoneId, date, deliveryInstruction } = values;
      if (!err) {
        const payload = {
          userId: userDetails.result.userId,
          cartId,
          deliverToSiteId: address[0].siteId,
          deliveryDate: date.format("YYYY-MM-DD"),
          deliveryTime: "12:00:00",
          deliveryInstruction,
          buildingId,
          floorId: floorId || null,
          roomId: roomId || null,
          zoneId: zoneId || null
        };
        createOrder(payload);
      }
    });
  };

  handleChange = (value, key) => {
    const { getUserFloor, getUserRoom, resetUserFloor, resetUserRoom, form } = this.props;
    if (key === "buildingId") {
      resetUserFloor();
      resetUserRoom();
      form.resetFields(["floorId", "roomId"]);
      this.setState(
        {
          buildingId: value
        },
        () => getUserFloor(value)
      );
    }
    if (key === "floorId") {
      resetUserRoom();
      form.resetFields("roomId");
      this.setState(
        {
          floorId: value
        },
        () => getUserRoom(value)
      );
    }
    if (key === "roomId") {
      this.setState({
        roomId: value
      });
    }
    if (key === "zoneId") {
      this.setState({
        zoneId: value
      });
    }
    if (key === "deliveryInstruction") {
      this.setState({
        deliveryInstruction: value
      });
    }
  };

  handleDateChange = (date, dateString) => {
    this.setState({
      date: dateString
    });
  };

  addItem(user, item, quantity, cartId) {
    this.setState({ enableSpinner: !this.state.enableSpinner, currentItem: item });
    this.props.addItemToCart(user, item, quantity, cartId);
    setTimeout(() => {
      this.setState({ enableSpinner: !this.state.enableSpinner, currentItem: null });
    }, 1000);
  }
  preCartedQuanity = (item, list) => {
    const itemValue = list.result
      ? list.result.content.userCartItemDetails.find(element => element.itemId === item.ItemId)
      : null;
    return itemValue ? itemValue.numberOfUnits : null;
  };

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  render() {
    const {
      building,
      floor,
      room,
      zone,
      cartId,
      cartDetails,
      userDetails,
      deleteItemFromCart
    } = this.props;
    const { cartSavedItemList } = cartDetails;
    const { getFieldDecorator } = this.props.form;
    return (
      <Layout {...this.props}>
        <Form labelCol={{ span: 5 }} wrapperCol={{ span: 12 }} onSubmit={this.handleSubmit}>
          <Row>
            <Col span={24}>
              {" "}
              <div className="steps">
                <Steps progressDot current={1}>
                  <Step title="Your Orders" />
                  <Step title="Delivery Channel" />
                  <Step title="Order Summaries" />
                  <Step title="Order Confirmation" />
                </Steps>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={24}>
              <div className="topdesc">
                <h1 className="heading">Delivery Channel and Address</h1>
              </div>
              <div className="card-container">
                <Tabs id="myAnchor" type="card">
                  <TabPane
                    tab={
                      <span>
                        <img src={onsitedelivery} alt="onsitedelivery" height="40px" width="45px" />
                        <p
                          style={{
                            paddingLeft: "20px",
                            display: "inline",
                            fontSize: "12px",
                            fontWeight: "600"
                          }}
                        >
                          On site Delivery
                        </p>
                      </span>
                    }
                    key="1"
                  >
                    <div className={Styles.formcontianer}>
                      <p className={Styles.title}>Deliver To:</p>

                      <Form.Item
                        wrapperCol={{ sm: 24 }}
                        style={{
                          width: "20%",
                          marginRight: 0,
                          display: "inline-block"
                        }}
                      >
                        <p style={{ marginBottom: "-12px" }}>Building</p>
                        {getFieldDecorator("buildingId", {
                          rules: [{ required: true, message: "Building is required!" }]
                        })(
                          <Select
                            style={{ width: "95%" }}
                            placeholder={"Building"}
                            onChange={value => this.handleChange(value, "buildingId")}
                            loading={building.fetching}
                            className={Styles.building}
                          >
                            {building.result.map(item => (
                              <Option value={item.buildingId} className={Styles.options}>
                                {item.buildingNumber}
                              </Option>
                            ))}
                          </Select>
                        )}
                      </Form.Item>

                      <Form.Item
                        wrapperCol={{ sm: 24 }}
                        style={{
                          width: "14%",
                          margin: "0px 0px 20px 0px",
                          display: "inline-block"
                        }}
                      >
                        <p style={{ marginBottom: "-12px" }}>Floor</p>
                        {getFieldDecorator("floorId", {
                          rules: [{ required: true, message: "Floor is required!" }]
                        })(
                          <Select
                            style={{ width: "95%" }}
                            placeholder={"Floor"}
                            onChange={value => this.handleChange(value, "floorId")}
                            defaultActiveFirstOption={true}
                            loading={floor.fetching}
                          >
                            {floor.result.map(item => (
                              <Option value={item.floorId} className={Styles.options}>
                                {item.floorNumber}
                              </Option>
                            ))}
                          </Select>
                        )}
                      </Form.Item>

                      <Form.Item
                        wrapperCol={{ sm: 24 }}
                        style={{
                          width: "14%",
                          margin: "0px 0px 20px 0px",
                          display: "inline-block"
                        }}
                      >
                        <p style={{ marginBottom: "-12px" }}>Room</p>
                        {getFieldDecorator("roomId", {
                          rules: [{ required: true, message: "Room is required!" }]
                        })(
                          <Select
                            style={{ width: "95%" }}
                            placeholder={"Room"}
                            onChange={value => this.handleChange(value, "roomId")}
                            loading={room.fetching}
                          >
                            {room.result.map(item => (
                              <Option value={item.roomId} className={Styles.options}>
                                {item.roomNumber}
                              </Option>
                            ))}
                          </Select>
                        )}
                      </Form.Item>

                      <Form.Item
                        wrapperCol={{ sm: 24 }}
                        style={{
                          width: "14%",
                          margin: "0px 0px 20px 0px",
                          display: "inline-block"
                        }}
                      >
                        <p style={{ marginBottom: "-12px" }}>Zone</p>
                        {getFieldDecorator("zoneId", {
                          rules: [{ required: true, message: "Zone is required!" }]
                        })(
                          <Select
                            style={{ width: "95%" }}
                            placeholder="Zone"
                            onChange={value => this.handleChange(value, "zoneId")}
                            loading={zone.fetching}
                          >
                            {zone.result.map(item => (
                              <Option value={item.zoneId} className={Styles.options}>
                                {item.zoneName}
                              </Option>
                            ))}
                          </Select>
                        )}
                      </Form.Item>

                      <Form.Item>
                        <p className={Styles.title}>Delivery Date:</p>
                        {getFieldDecorator("date", {
                          rules: [{ required: true, message: "Date is required!" }]
                        })(
                          <DatePicker
                            format={dateFormatList}
                            onChange={this.handleDateChange}
                            disabledDate={current => {
                              return current && current < moment();
                            }}
                          />
                        )}
                      </Form.Item>

                      <Form.Item>
                        <p className={Styles.title}>Delivery Instructions</p>
                        {getFieldDecorator("deliveryInstruction", {
                          rules: [{ required: false }]
                        })(<TextArea rows={4} style={{ marginBottom: "17px" }} />)}
                      </Form.Item>
                    </div>
                  </TabPane>
                  <TabPane
                    tab={
                      <span>
                        <img
                          src={collectfromstore}
                          alt="collectfromstore"
                          height="35px"
                          width="40px"
                          class="disabled"
                        />
                        <p
                          style={{
                            paddingLeft: "20px",
                            display: "inline",
                            color: "#bfbfbf",
                            fontSize: "12px",
                            fontWeight: "600"
                          }}
                        >
                          Collect from store
                        </p>
                      </span>
                    }
                    disabled
                    key="2"
                  >
                    <Collectfromstore />{" "}
                  </TabPane>
                </Tabs>

                <div>
                  <Row>
                    <hr className="separator" />
                  </Row>
                  <Row type="flex" justify="center" align="middle">
                    <Col span={24}>
                      {cartSavedItemList.result &&
                      cartSavedItemList.result.content.userCartItemDetails.length !== 0 ? (
                        cartSavedItemList.result.content.userCartItemDetails.map((item, index) => (
                          <div key={item.ItemId} className="card_container">
                            <CartItem
                              cartItem={item}
                              deleteIcon={true}
                              cartedQuantity={this.preCartedQuanity(item, cartSavedItemList)}
                              addItemToCart={(item, quantity) => {
                                this.addItem(userDetails.result, item, quantity, cartId);
                              }}
                              deleteItemFromCart={item => {
                                deleteItemFromCart(userDetails.result, item, cartId);
                              }}
                            />
                          </div>
                        ))
                      ) : (
                        <ErrorHandler errorCase={"itemNotFound"} />
                      )}
                    </Col>
                    <Form.Item style={{ width: "100%", marginRight: 0 }} wrapperCol={24}>
                      <Button
                        type="primary"
                        size="large"
                        className="placeorderbackbutton"
                        ghost
                        onClick={() =>
                          cartSavedItemList.result &&
                          cartSavedItemList.result.content.userCartItemDetails.length !== 0
                            ? this.props.history.push("/savedCart")
                            : this.props.history.push("/orderNow")
                        }
                      >
                        Back
                      </Button>
                      <Button
                        type="primary"
                        size="large"
                        className="placeorderbutton"
                        htmlType="submit"
                        disabled={
                          cartSavedItemList.result &&
                          cartSavedItemList.result.content.userCartItemDetails.length !== 0
                            ? false
                            : true
                        }
                        onClick={() => {
                          this.scrollToTop();
                        }}
                      >
                        Place Order
                      </Button>
                    </Form.Item>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </Layout>
    );
  }
}
const mapStateToProps = state => {
  return {
    userDetails: state.user.info,
    cartId: state.user.cartId.result,
    address: state.user.address.result,
    building: state.user.building,
    floor: state.user.floor,
    room: state.user.room,
    zone: state.user.zone,
    cartDetails: state.cart.cartDetails,
    placeOrderInfo: state.placeOrder.info
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getUserBuilding: () => dispatch(getUserBuildingAction()),
    getUserFloor: buildingId => dispatch(getUserfloorAction(buildingId)),
    getUserRoom: floorId => dispatch(getUserRoomAction(floorId)),
    getUserZone: () => dispatch(getUserZoneAction()),
    getCartProductList: event => dispatch(getCartProductListAction({})),
    getCartSavedListData: (event, currentPage, pageSize, cartId) =>
      dispatch(
        getCartSavedListAction({
          userId: event ? event.userId : null,
          cartId: cartId ? cartId : Number(localStorage.getItem("cartId")),
          currentPage: currentPage,
          pageSize: pageSize
        })
      ),

    addItemToCart: (event, ItemId, quantity, cartId) =>
      dispatch(
        addCartListItemsAction({
          userId: event ? event.userId : null,
          cartId: cartId ? cartId : Number(localStorage.getItem("cartId")),
          quantity: quantity,
          itemId: ItemId
        })
      ),
    deleteItemFromCart: (event, ItemId, cartId) =>
      dispatch(
        deleteCartListItemsAction({
          userId: event ? event.userId : null,
          cartId: cartId ? cartId : Number(localStorage.getItem("cartId")),
          itemId: ItemId
        })
      ),
    createOrder: payload => dispatch(createOrderAction(payload)),
    resetUserFloor: () => dispatch(resetUserFloorAction()),
    resetUserRoom: () => dispatch(resetUserRoomAction())
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: "DeliveryChannelAndAddress" })(DeliveryChannelAndAddress));
