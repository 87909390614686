import { takeLatest, call } from "redux-saga/effects";

import * as actions from "./deliveryChannelAndAddressAction";
import { postCreateOrderWorker } from "./deliveryChannelAndAddressWorkers";

// watch for every purchase completion, and start the purchaseFlow
export function* watchPostCreateOrder() {
  yield takeLatest(actions.POST_CREATE_ORDER, function*(loadAction) {
    yield call(postCreateOrderWorker, loadAction.payload);
  });
}
