export const GET_ORDER_SUMMARY = "GET_ORDER_SUMMARY";
export const GET_ORDER_SUMMARY_SUCCESS = "GET_ORDER_SUMMARY_SUCCESS";
export const GET_ORDER_SUMMARY_ERROR = "GET_ORDER_SUMMARY_ERROR";

export const POST_ORDER_STATUS = "POST_ORDER_STATUS";
export const POST_ORDER_STATUS_SUCCESS = "POST_ORDER_STATUS_SUCCESS";
export const POST_ORDER_STATUS_ERROR = "POST_ORDER_STATUS_ERROR";

export const PRE_ORDER_STATUS_SUCCESS = "PRE_ORDER_STATUS_SUCCESS";
export const PRE_ORDER_STATUS_ERROR = "PRE_ORDER_STATUS_ERROR";
export const PRE_ORDER_STATUS = "PRE_ORDER_STATUS";

export const POST_INITIATE_CART = "POST_INITIATE_CART";
export const POST_INITIATE_CART_SUCCESS = "POST_INITIATE_CART_SUCCESS";
export const POST_INITIATE_CART_ERROR = "POST_INITIATE_CART_ERROR";

export const getUserOrder = payload => ({
  type: GET_ORDER_SUMMARY,
  payload
});

export const userStatusLookUp = payload => ({
  type: POST_ORDER_STATUS,
  payload
});

export const userStatusId = (payload, statusId) => ({
  type: PRE_ORDER_STATUS,
  payload,
  statusId
});

export const initiateCart = payload => ({
  type: POST_INITIATE_CART,
  payload
});
