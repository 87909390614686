import { combineReducers } from "redux";

import * as actions from "./loginAction";

const initialState = { result: null, error: null, fetching: false };
const addressInitialState = { result: [], error: null, fetching: false };

const login = (state = initialState, action) => {
  switch (action.type) {
    case actions.POST_USER_LOGIN:
      return { ...state, fetching: true };
    case actions.POST_USER_LOGIN_SUCCESS:
      return { result: action.result, error: null, fetching: false };
    case actions.POST_USER_LOGIN_ERROR:
      return { result: null, error: action.error, fetching: false };
    default:
      return { ...state };
  }
};

const info = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_USER:
      return { ...state, fetching: true };
    case actions.GET_USER_SUCCESS:
      return { result: action.result, error: null, fetching: false };
    case actions.GET_USER_ERROR:
      return { result: null, error: action.error, fetching: false };
    default:
      return { ...state };
  }
};
const address = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_USER_ADDRESS:
      return { ...state, fetching: true };
    case actions.GET_USER_ADDRESS_SUCCESS:
      return { result: action.result, error: null, fetching: false };
    case actions.GET_USER_ADDRESS_ERROR:
      return { result: null, error: action.error, fetching: false };
    default:
      return { ...state };
  }
};
const building = (state = addressInitialState, action) => {
  switch (action.type) {
    case actions.GET_USER_BUILDING:
      return { ...state, fetching: true };
    case actions.GET_USER_BUILDING_SUCCESS:
      return { result: action.result, error: null, fetching: false };
    case actions.GET_USER_BUILDING_ERROR:
      return { result: null, error: action.error, fetching: false };
    default:
      return { ...state };
  }
};
const floor = (state = addressInitialState, action) => {
  switch (action.type) {
    case actions.GET_USER_FLOOR:
      return { ...state, fetching: true };
    case actions.GET_USER_FLOOR_SUCCESS:
      return { result: action.result, error: null, fetching: false };
    case actions.GET_USER_FLOOR_ERROR:
      return { ...addressInitialState, error: action.error, fetching: false };
    case actions.RESET_USER_FLOOR:
      return { ...addressInitialState };
    default:
      return { ...state };
  }
};

const room = (state = addressInitialState, action) => {
  switch (action.type) {
    case actions.GET_USER_ROOM:
      return { ...state, fetching: true };
    case actions.GET_USER_ROOM_SUCCESS:
      return { result: action.result, error: null, fetching: false };
    case actions.GET_USER_ROOM_ERROR:
      return { ...addressInitialState, error: action.error, fetching: false };
    case actions.RESET_USER_ROOM:
      return { ...addressInitialState };
    default:
      return { ...state };
  }
};

const zone = (state = addressInitialState, action) => {
  switch (action.type) {
    case actions.GET_USER_ZONE:
      return { ...state, fetching: true };
    case actions.GET_USER_ZONE_SUCCESS:
      return { result: action.result, error: null, fetching: false };
    case actions.GET_USER_ZONE_ERROR:
      return { ...addressInitialState, error: action.error, fetching: false };
    default:
      return { ...state };
  }
};

const cartId = (state = initialState, action) => {
  switch (action.type) {
    case actions.POST_INITIATE_CART:
      return { ...state, fetching: true };
    case actions.POST_INITIATE_CART_SUCCESS:
      return { result: action.result.cartId, error: null, fetching: false };
    case actions.POST_INITIATE_CART_ERROR:
      return { result: null, error: action.error, fetching: false };
    default:
      return { ...state };
  }
};

export default combineReducers({
  login,
  info,
  address,
  building,
  floor,
  room,
  zone,
  cartId
});
