import React from "react";
import { connect } from "react-redux";

import { Layout, Row, Col, Icon } from "antd";
// import logo from "../../assets/logo.png";
import logo from "../../assets/sgn-logo.png";
import "./home.css";
import bGimage from "../../assets/bGimage.png";
import Styles from "./home.module.css";
import Tab from "../../components/tab";
import Card from "../../components/card";
import UserInfo from "../../components/userinfo";
import { getActivitiesAction } from "./homeAction";
import { getUserAction, initiateCartAction } from "../login/loginAction";

import auth from "../../utils/auth";

const { Content } = Layout;

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedActivityId: null,
      selectedModeOfDeliveryId: []
    };
  }
  componentDidMount = () => {
    const { activities, userInfo } = this.props;
    if (activities && activities.result && activities.result.length === 0) {
      this.props.getActivities();
    }

    if (!(userInfo && userInfo.result)) {
      const userId = auth.getAuthToken(); //get the userId from somehwer
      this.props.getUser(userId);
      this.props.initiateCart(userId);
    }
  };
  cardClickHandler = selectedActivityId => {
    this.setState({
      selectedActivityId
    });
    if (selectedActivityId == "2") {
      this.props.history.push("./myorders");
    } else if (selectedActivityId == "3") {
      this.props.history.push("./ordernow");
    }
  };
  handleClick = () => {
    const { selectedActivityId } = this.state;
    let path = "";
    switch (selectedActivityId) {
      case 1:
        path = "/ordernow";
        break;
      case 2:
        path = "/myorders";
        break;
      case 3:
        path = "/inventory";
        break;
      default:
        path = "";
        break;
    }
    this.props.history.push(path);
  };
  getModeOfDelivery = () => {
    const { selectedActivityId } = this.state;
    const selected = this.props.activities.result.filter(
      item => item.activityId === selectedActivityId
    );
    return selected[0].modeOfDelivery;
  };
  render() {
    const { activities, userInfo } = this.props;
    const { selectedActivityId } = this.state;

    return (
      <div className={Styles.homearea}>
        <Row>
          <Col span={4} className={Styles.sidebar}>
            <div className={Styles.logo}>
              <img src={logo} alt="Logo" height="80%" width="70%" />
              <p style={{ color: "white", fontSize: "12px", marginTop: "10px" }}>
                SGN Your gas. Our network.
              </p>
            </div>
            <div className={Styles.bGimage}>
              <img src={bGimage} alt="Background_Image" height="100%" width="100%" />
            </div>
          </Col>
          <Col span={20} offset={4}>
            <header className={Styles.header}>
              <ul className={Styles.headerlist}>
                <li className={Styles.dropdown}>
                  <a href="#">
                    {this.props.userInfo.result ? (
                      <UserInfo data={this.props.userInfo.result} />
                    ) : null}
                  </a>
                  <div className={Styles.arrow_box}>
                    <a href="./logout">Logout</a>
                  </div>
                </li>

                <li className={Styles.headerlistitem}>
                  <a className={Styles.help} href="#">
                    Help
                  </a>
                </li>

                <li className={Styles.headerlistitem}>
                  <Icon className={Styles.questionCircle} type="question-circle" />
                </li>
                <li className={Styles.headerlistitem}>
                  <Icon className={Styles.bell} type="bell" />
                </li>
              </ul>
            </header>
            <Content>
              <div className={Styles.content}>
                <div className={Styles.title}>
                  <h3 className={Styles.homepageheading}>Welcome to</h3>
                  <h4 className={Styles.mehtitle}>SGN Engineer Tool</h4>
                </div>

                {activities.result ? (
                  <>
                    <Card
                      onClick={param => this.cardClickHandler(param)}
                      selectedActivityId={selectedActivityId}
                      data={activities.result}
                    />
                    {selectedActivityId ? (
                      <Tab data={this.getModeOfDelivery()} handleClick={this.handleClick} />
                    ) : null}
                  </>
                ) : null}
              </div>
            </Content>
            <div className={Styles.footer}>
              <p>© SGN Engineer Tool, 2024</p>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    activities: state.home.activities,
    userInfo: state.user.info
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getActivities: () => dispatch(getActivitiesAction()),
    getUser: userId => dispatch(getUserAction(userId)),
    initiateCart: userId => dispatch(initiateCartAction({ userId }))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);
