import React, { Component } from "react";
import { connect } from "react-redux";
import { debounce } from "lodash";
import queryString from "query-string";

import Layout from "../../components/layout";
import CartItem from "./cart-item/cart-item";
import {
  getCartSavedListAction,
  getCartListItemsAction,
  addCartListItemsAction,
  deleteCartListItemsAction,
  getCartProductListAction
} from "./orderNowAction";
import { Row, Col, Input, Button, Badge, Spin, Select, Card, Pagination } from "antd";
import ErrorHandler from "../error-handling/error-handler";

import cartIcon from "../../assets/group-5.svg";

import "./orderNow.css";

class CartList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: null,
      selectedValue: 0,
      loadItem: false,
      currentPage: 1,
      pageSize: 7,
      enableSpinner: false,
      currentItem: null
    };
    this.addItem = debounce(this.addItem, 1000);
    this.onShowSizeChange = this.onShowSizeChange.bind(this);
  }

  componentDidMount() {
    const {
      getCartSavedListData,
      getCartListItemsData,
      userDetails,
      getCartProductList,
      cartId
    } = this.props;
    getCartProductList(userDetails.result);

    let params = queryString.parse(this.props.location.search);
    this.setState({
      searchValue: params.searchValue ? params.searchValue : null,
      selectedValue: params.selectedValue ? params.selectedValue : 0
    });
    getCartListItemsData(
      this.state.selectedValue,
      "dropDown",
      this.state.currentPage,
      this.state.pageSize
    );
    if (userDetails.result && userDetails.result.userId && cartId) {
      getCartSavedListData(userDetails.result, 1, 1000, cartId);
    }
  }

  componentDidUpdate(prevProps) {
    const { getCartSavedListData, userDetails, cartId } = this.props;
    if (prevProps.userDetails.result !== this.props.userDetails.result) {
      if (
        this.props.userDetails.result &&
        this.props.userDetails.result.userId &&
        this.props.cartId
      ) {
        getCartSavedListData(
          userDetails.result,
          this.state.currentPage,
          this.state.pageSize,
          cartId
        );
      }
    }
    if (
      (prevProps.cartDetails.addCartItem.result !== this.props.cartDetails.addCartItem.result &&
        this.props.cartDetails.addCartItem.result) ||
      (prevProps.cartDetails.deleteCartItem.result !==
        this.props.cartDetails.deleteCartItem.result &&
        this.props.cartDetails.deleteCartItem.result)
    ) {
      getCartSavedListData(userDetails.result, this.state.currentPage, this.state.pageSize, cartId);
    }
  }
  selectedItem = value => {
    this.setState({ selectedValue: value }, () =>
      this.props.history.push(
        `/ordernow?selectedValue=${this.state.selectedValue}&searchValue=${this.state.searchValue}`
      )
    );
    this.setState({ currentPage: 1 }, () =>
      this.props.getCartListItemsData(
        value,
        "dropDown",
        this.state.currentPage,
        this.state.pageSize
      )
    );
  };
  searchItem = item => {
    this.setState({ currentPage: 1 }, () =>
      this.props.getCartListItemsData(item, "search", this.state.currentPage, this.state.pageSize)
    );
  };
  preCartedQuanity = (item, list) => {
    const itemValue = list.result
      ? list.result.content.userCartItemDetails.find(element => element.itemId === item.ItemId)
      : null;
    return itemValue ? itemValue.numberOfUnits : null;
  };
  addItem(user, item, quantity, cartId) {
    this.props.addItemToCart(user, item, quantity, cartId);
    this.setState({ enableSpinner: !this.state.enableSpinner, currentItem: item });
    setTimeout(() => {
      this.setState({ enableSpinner: !this.state.enableSpinner, currentItem: null });
    }, 1000);
  }
  returnSelectedItem = item => {
    const selectedItem = this.props.cartDetails.cartProductList.result.find(
      element => element.categoryId === Number(item)
    );
    return (
      <div className="descriptiontop">
        <span className="selected-item">
          {item === 0 || item === undefined ? "All Products" : selectedItem.categoryName}
        </span>{" "}
        <br />
        <span className="Showing-pagination">
          {this.props.cartDetails.cartItemList.result.totalElements
            ? `Showing ${this.props.cartDetails.cartItemList.result.totalElementsInCurrentPage} of ${this.props.cartDetails.cartItemList.result.totalElements}`
            : "No Items"}
        </span>
      </div>
    );
  };
  onShowSizeChange(currentPage, pageSize) {
    this.setState({ currentPage: currentPage }, () =>
      this.props.getCartListItemsData(this.state.selectedValue, "dropDown", currentPage, pageSize)
    );
  }
  render() {
    const { Search } = Input;
    const { Option } = Select;
    const { cartDetails, userDetails, deleteItemFromCart, cartId } = this.props;
    const { cartSavedItemList, cartProductList, cartItemList } = cartDetails;

    return (
      <Layout {...this.props}>
        <div className="ordernow-containt">
          <div className="cart_page_container">
            <Card className="search-container" style={{ height: 65 }}>
              <Row type="flex" justify="center">
                <Col span={8}>
                  <div className="product_dropdown">
                    <div className="selectbox">
                      <Select
                        defaultValue={this.state.selectedValue}
                        onChange={value => this.selectedItem(value)}
                        // size="large"
                        style={{ width: 200, fontSize: "16px" }}
                        placeholder="Please select an item"
                      >
                        <Option className="options" value={0}>
                          All Products
                        </Option>
                        {cartProductList.result
                          ? cartProductList.result.map(item => (
                              <Option
                                className="options"
                                value={item.categoryId}
                                key={item.categoryId}
                              >
                                {item.categoryName}
                              </Option>
                            ))
                          : ""}
                      </Select>
                    </div>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="product_search">
                    <Search
                      onSearch={value => {
                        this.searchItem(value);
                      }}
                      onChange={e =>
                        this.setState({ searchValue: e.target.value }, () =>
                          this.props.history.push(
                            `/ordernow?selectedValue=${this.state.selectedValue}&&searchValue=${this.state.searchValue}`
                          )
                        )
                      }
                      value={this.state.searchValue}
                      placeholder="Search"
                      style={{ width: 380 }}
                    />
                  </div>
                </Col>
                <Col span={4}>
                  <div className="cartbadgeicon">
                    <Badge
                      count={
                        cartSavedItemList.result
                          ? cartSavedItemList.result.content.userCartItemDetails.length
                          : 0
                      }
                      style={{ backgroundColor: "#52c41a" }}
                    >
                      <Button
                        type="primary"
                        className="cart_div"
                        onClick={() => {
                          this.props.history.push("/savedCart");
                        }}
                      >
                        <img className="cart_div_icon" src={cartIcon} alt="cart" />
                      </Button>
                    </Badge>
                  </div>
                </Col>
              </Row>
            </Card>
          </div>
          <div style={{ margin: "30px" }}> </div>
          {(!cartItemList.result && !cartSavedItemList.result) ||
          (cartItemList.fetching && cartSavedItemList.result) ? (
            <Spin className="spinner" size="large" tip="Loading..." />
          ) : (
            <>
              {cartItemList.result && cartItemList.result.content.length !== 0 ? (
                <div className="cart-items-container">
                  {cartProductList.result ? this.returnSelectedItem(this.state.selectedValue) : ""}
                  <div style={{ background: "#fff", margin: "0px 30px 30px 30px" }}>
                    <Row type="flex" justify="center" align="middle" className="panelrow">
                      <Col span={24}>
                        {cartItemList.result.content.map((item, index) => (
                          <div key={item.ItemId} className="card_container">
                            <CartItem
                              cartItem={item}
                              deleteIcon={false}
                              cartedQuantity={this.preCartedQuanity(item, cartSavedItemList)}
                              addItemToCart={(item, quantity) => {
                                this.addItem(userDetails.result, item, quantity, cartId);
                              }}
                              deleteItemFromCart={item => {
                                deleteItemFromCart(userDetails.result, item, cartId);
                              }}
                              spinner={
                                this.state.currentItem === item.ItemId
                                  ? this.state.enableSpinner
                                  : false
                              }
                            />
                          </div>
                        ))}
                      </Col>
                    </Row>
                  </div>
                  <Row type="flex" justify="center" align="middle">
                    <Col span={24}>
                      {cartItemList.result && cartItemList.result.length !== 0 ? (
                        <div className="pagination">
                          <Pagination
                            onChange={this.onShowSizeChange}
                            defaultCurrent={this.state.currentPage}
                            total={cartItemList.result.totalElements}
                            showTotal={(total, range) =>
                              `${range[0]}-${range[1]} of ${total} items`
                            }
                            pageSize={this.state.pageSize}
                          />
                        </div>
                      ) : null}
                    </Col>
                  </Row>
                </div>
              ) : (
                <ErrorHandler errorCase={"itemNotFound"} />
              )}
            </>
          )}
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  userDetails: state.user.info,
  cartId: state.user.cartId.result,
  cartDetails: state.cart.cartDetails
});

const mapDispatchToProps = dispatch => ({
  getCartProductList: event => dispatch(getCartProductListAction({})),
  getCartSavedListData: (event, currentPage, pageSize, cartId) =>
    dispatch(
      getCartSavedListAction({
        userId: event ? event.userId : null,
        cartId: cartId ? cartId : Number(localStorage.getItem("cartId")),
        currentPage: currentPage,
        pageSize: pageSize
      })
    ),
  getCartListItemsData: (event, type, currentPage, pageSize) =>
    dispatch(
      getCartListItemsAction({
        value: event,
        type: type,
        currentPage: currentPage,
        pageSize: pageSize
      })
    ),
  addItemToCart: (event, ItemId, quantity, cartId) =>
    dispatch(
      addCartListItemsAction({
        userId: event ? event.userId : null,
        cartId: cartId ? cartId : Number(localStorage.getItem("cartId")),
        quantity: quantity,
        itemId: ItemId
      })
    ),
  deleteItemFromCart: (event, ItemId, cartId) =>
    dispatch(
      deleteCartListItemsAction({
        userId: event ? event.userId : null,
        cartId: cartId ? cartId : Number(localStorage.getItem("cartId")),
        itemId: ItemId
      })
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(CartList);
