import { takeLatest, call } from "redux-saga/effects";

import * as actions from "./myOrderAction";
import { getMyOrder } from "./myOrderWorkers";

export function* watchGetMyOrder() {
  yield takeLatest(actions.MY_ORDER, function*(loadAction) {
    yield call(getMyOrder, loadAction.payload);
  });
}
