import React from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";

import Footer from "../footer/footer";
// import listinglogo from "../../assets/listinglogo.png";
import listinglogo from "../../assets/sgn-logo.png";
import addressImage from "../../assets/address.svg";
import Card from "../card";
import Header from "../header";
import Styles from "./layout.module.css";
import { getActivitiesAction } from "../../pages/home/homeAction";
import {
  getUserAction,
  getUserAddressAction,
  initiateCartAction
} from "../../pages/login/loginAction";

import auth from "../../utils/auth";

class Layout extends React.Component {
  constructor(props) {
    super(props);
    let selectedActivityId = 1;
    const path = this.props.location.pathname;
    switch (path) {
      case "/ordernow":
        selectedActivityId = 1;
        break;
      case "/myorders":
        selectedActivityId = 2;
        break;
      case "/inventory":
        selectedActivityId = 3;
        break;
      default:
        selectedActivityId = 1;
        break;
    }

    this.state = {
      selectedActivityId
    };
  }

  componentDidMount = () => {
    const {
      activities,
      userInfo,
      userAddress,
      getUserAddress,
      getActivities,
      getUser,
      cartId,
      initiateCart
    } = this.props;

    const userId = auth.getAuthToken();
    if (activities && activities.result && activities.result.length === 0) {
      getActivities();
    }

    if (!(userInfo && userInfo.result)) {
      getUser(userId);
    }
    if (!(userAddress && userAddress.result)) {
      getUserAddress(userId); // get the actual userId
    }

    if (!cartId) {
      initiateCart(userId); // get the actual userId
    }
  };

  cardClickHandler = selectedActivityId => {
    if (selectedActivityId !== this.state.selectedActivityId) {
      this.setState(
        {
          selectedActivityId
        },
        () => {
          const { selectedActivityId } = this.state;
          let path = "";
          switch (selectedActivityId) {
            case 1:
              path = "/ordernow";
              break;
            case 2:
              path = "/myorders";
              break;
            case 3:
              path = "/inventory";
              break;
            default:
              path = "";
              break;
          }

          this.props.history.push(path);
        }
      );
    }
  };
  render() {
    const { activities, children, userAddress } = this.props;
    const { selectedActivityId } = this.state;
    const address =
      userAddress && userAddress.result && userAddress.result.length > 0
        ? userAddress.result[0]
        : null;

    return (
      <div className={Styles.mainpage}>
        <Row>
          <Col span={4} className={Styles.sidebarlayout}>
            <div className={Styles.logo}>
              <img
                src={listinglogo}
                alt="Logo"
                height="80%"
                width="80%"
                onClick={() => this.props.history.push("/")}
              />
              <p style={{ color: "white", fontSize: "12px", marginTop: "10px" }}>
                SGN Your gas. Our network.
              </p>
            </div>
            <Card
              onClick={param => this.cardClickHandler(param)}
              selectedActivityId={selectedActivityId}
              data={activities.result}
              type="vertical"
            />
          </Col>
          <Col span={20} offset={4}>
            <div className={Styles.layoutHeader}>
              <Row style={{ backgroundColor: "#ffffff", height: "50px" }}>
                <Col span={10}>
                  {address ? (
                    <div className={Styles.address}>
                      <img src={addressImage} alt="address" height="33px" width="22px" />

                      <div style={{ paddingLeft: "10px" }}>
                        <p style={{ margin: "0px", fontWeight: "600" }}>My address:</p>
                        <p>{`${address.siteAddress}, ${address.country.city.cityName}, ${address.country.countryName}`}</p>
                      </div>
                    </div>
                  ) : null}
                </Col>
                <Col span={14}>
                  <Header {...this.props} />
                </Col>
              </Row>
            </div>
            <div className={Styles.layoutContent}>
              <Row>{children}</Row>
            </div>
          </Col>
        </Row>
        <div className={Styles.layoutfooter}>
          <Footer />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    activities: state.home.activities,
    userInfo: state.user.info,
    userAddress: state.user.address,
    cartId: state.user.cartId.result
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getActivities: () => dispatch(getActivitiesAction()),
    getUser: userId => dispatch(getUserAction(userId)),
    getUserAddress: userId => dispatch(getUserAddressAction(userId)),
    initiateCart: userId => dispatch(initiateCartAction({ userId }))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
