import React from "react";

import Layout from "../../components/layout";
import Styles from "./inventory.module.css";

class Inventory extends React.Component {
  render() {
    return (
      <Layout {...this.props}>
        <div className={Styles.inventorycontainer}>
          <h1>inventory page</h1>
        </div>
      </Layout>
    );
  }
}

export default Inventory;
