import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import LoadingBar from "react-redux-loading-bar";

import Login from "./pages/login";
import ForgetPassword from "./pages/forget-password";
import Home from "./pages/home";
import OrderNow from "./pages/order-now";
import SavedCartList from "./pages/saved-cart";
import DeliveryChannel from "./pages/delivery-channel";
import Confirmorder from "./pages/confirm-order";
import OrderSummary from "./pages/order-summary";

import NotFound from "./pages/not-found";
import MyOrders from "./pages/myorders";
import Inventory from "./pages/inventory";
import Logout from "./pages/logout";
import auth from "./utils/auth";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (auth.getAuthToken() !== "") {
        return <Component {...props} />;
      }
      return <Redirect to="/login" />;
    }}
  />
);

export default () => {
  return (
    <div>
      <LoadingBar style={{ backgroundColor: "#77B722", height: "4px" }} />
      <Router>
        <Switch>
          <PrivateRoute exact path="/" component={Home} />
          <PrivateRoute path="/home" component={Home} />=
          <PrivateRoute path="/ordernow" component={OrderNow} />
          <PrivateRoute path="/myorders" component={MyOrders} />
          <PrivateRoute path="/inventory" component={Inventory} />
          <PrivateRoute path="/savedCart" component={SavedCartList} />
          <PrivateRoute path="/logout" component={Logout} />
          <PrivateRoute path="/order-confirm" component={Confirmorder} />
          <PrivateRoute path="/order-summary" component={OrderSummary} />
          <Route path="/login" component={Login} />
          <Route path="/delivery-channel" component={DeliveryChannel} />
          <Route path="/forget-password" component={ForgetPassword} />
          <Route path="/*" component={NotFound} />
        </Switch>
      </Router>
    </div>
  );
};
