import React from "react";

import { Row, Col } from "antd";

import Styles from "./card.module.css";

const Box = ({ data, selectedActivityId, onClick = () => {}, type = "horizontal" }) => {
  const getImageName = name => {
    const transformedName = name
      .split(" ")
      .join("")
      .toLowerCase();
    return transformedName;
  };

  return (
    <div className={`${Styles.box} ${type === "vertical" ? Styles.boxVertical : null}`}>
      <Row gutter={16}>
        {data.map((item, index) => (
          <Col span={type === "vertical" ? 24 : 8} key={index}>
            <div
              className={`${Styles.card} ${type === "vertical" ? Styles.cardVertical : null}  ${
                Styles[`${getImageName(item.activityName)}`]
              } ${selectedActivityId === item.activityId ? Styles.active : null} 
            ${item.activityId === 3 ? Styles.disabled : ""}`}
              role="button"
              onClick={() => onClick(item.activityId)}
            >
              <div className={Styles.subcard}>
                <img
                  src={require(`../../assets/${getImageName(item.activityName)}.svg`)}
                  alt="place order"
                  className={Styles.cardimg}
                />
                <div style={{ padding: "10px 0px 0px 0px" }}>
                  <p>{item.activityId == 3 ? "Arrange Collection" : item.activityName}</p>
                </div>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};
export default Box;
