import React from "react";
import { connect } from "react-redux";

import { Row, Col, Icon, Button, Steps } from "antd";

import Layout from "../../components/layout";
import { getUserOrder } from "../order-summary/orderSummaryAction";
import UserName from "../../components/userName";
import { getUserAction } from "../login/loginAction";

import Styles from "./confirmOrder.module.css";
import "./confirmorder.css";
import auth from "../../utils/auth";

const { Step } = Steps;

class Confirmorder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }
  componentDidMount = () => {
    const { placeOrderInfo } = this.props;
    console.log("@@@@@", this.props);
    this.props.userOrder(placeOrderInfo.result.orderId);

    const { userInfo } = this.props;

    if (!(userInfo && userInfo.result)) {
      const userId = auth.getAuthToken(); //get the userId from somehwer
      this.props.getUser(userId);
    }
  };
  render() {
    const { placeOrderInfo } = this.props;
    console.log("hii", this.props);
    return (
      <Layout {...this.props}>
        <div className={Styles.Confirmorderbody}>
          <Row>
            <Col span={24}>
              {" "}
              <div className="steps">
                <Steps current={3} progressDot>
                  <Step title="Your Orders" />
                  <Step title="Delivery Channel" />
                  <Step title="Order Summaries" />
                  <Step title="Order Confirmation" />
                </Steps>
              </div>
            </Col>
          </Row>

          <div>
            <Row>
              <Col span={24}>
                <div className={Styles.Confirmorder}>
                  <Icon className={Styles.icon} type="check-circle" theme="filled" />

                  <div>
                    <h1 className={Styles.username}>
                      {this.props.userInfo.result ? (
                        <UserName data={this.props.userInfo.result} />
                      ) : null}
                    </h1>
                  </div>
                  <h2 className={Styles.massage}>Your Order has been Submitted</h2>

                  <h5 className={Styles.orderreference}>
                    {placeOrderInfo.result && placeOrderInfo.result.orderId ? (
                      <div>Your Order reference is {placeOrderInfo.result.orderCode}</div>
                    ) : null}
                  </h5>

                  <div style={{ height: "60px" }}>
                    <Button
                      type="primary"
                      className={Styles.button}
                      onClick={() => this.props.history.push("/logout")}
                    >
                      Logoff
                    </Button>
                    <Button
                      className={Styles.buttonhome}
                      onClick={() => this.props.history.push("/")}
                    >
                      Home
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Layout>
    );
  }
}
const mapStateToProps = state => {
  return {
    userInfo: state.user.info,
    orderDetails: state.orderSummary.details,
    placeOrderInfo: state.placeOrder.info
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getUser: userId => dispatch(getUserAction(userId)),
    userOrder: orderId => dispatch(getUserOrder(orderId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Confirmorder);
