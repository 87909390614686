import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Layout, Button, Steps, Popover, Row, Col } from "antd";
import { getUserOrder, userStatusLookUp, userStatusId, initiateCart } from "./orderSummaryAction";
import { getCartSavedListAction } from "../order-now/orderNowAction";
import { default as MepLayout } from "../../components/layout";
import Styles from "./orderSummary.module.css";
import Calendar from "../../assets/group-2.svg";
import Phone from "../../assets/group-6.svg";
import Location from "../../assets/group-4.svg";
import Contact from "../../assets/group-5-1.svg";
import "./ordersummary.css";

const { Content } = Layout;
const { Step } = Steps;

class OrderSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }

  componentDidMount = () => {
    const { placeOrderInfo } = this.props;

    this.props.userStatus();
    if (placeOrderInfo.result !== null) {
      this.props.userOrder(this.props.placeOrderInfo.result.orderId);
    }
  };

  confirmOrder = async () => {
    const { orderStatus, placeOrderInfo, userInfo, cartId, getCartSavedListData } = this.props;

    if (orderStatus.result !== null && placeOrderInfo.result !== null) {
      const statusId = orderStatus.result.status[1].lookUpValueId;
      const orderId = placeOrderInfo.result.orderId;
      const userId = userInfo.result.userId;

      this.props.status(orderId, statusId);
      await this.props.initiateCart(userId);
      await setTimeout(() => {
        getCartSavedListData(userInfo.result, 1, 1000, cartId + 1);
        localStorage.setItem("cartId", cartId + 1);
      }, 800);

      this.props.history.push("/order-confirm");
    }
  };

  cancelcart = async () => {
    const { orderStatus, placeOrderInfo, userInfo, cartId, getCartSavedListData } = this.props;

    if (orderStatus.result !== null && placeOrderInfo.result !== null) {
      const statusId = orderStatus.result.status[2].lookUpValueId;
      const orderId = placeOrderInfo.result.orderId;
      const userId = userInfo.result.userId;

      this.props.status(orderId, statusId);
      await this.props.initiateCart(userId);
      await setTimeout(() => {
        getCartSavedListData(userInfo.result, 1, 1000, cartId + 1);
        localStorage.setItem("cartId", cartId + 1);
      }, 800);
      this.props.history.push("/home");
    }
  };

  render() {
    const { orderDetails } = this.props;
    console.log(orderDetails);
    return (
      <MepLayout {...this.props}>
        <Layout>
          <Row>
            <Col span={24}>
              {" "}
              <div className="steps">
                <Steps current={2} progressDot>
                  <Step title="Your Orders" />
                  <Step title="Delivery Channel" />
                  <Step title="Order Summaries" />
                  <Step title="Order Confirmation" />
                </Steps>
              </div>
            </Col>
          </Row>
          <h1 className={Styles.heading}>Order Summary</h1>
          <Content
            style={{
              margin: "20px 20px 10px 30px",
              padding: "12px",
              background: "#fff",
              minHeight: 280
            }}
          >
            <h1 className={Styles.subHeading}>Delivery Details</h1>
            <div className={Styles.deliverytable}>
              <table className={Styles.deliveryDetails}>
                <thead>
                  <tr>
                    <th>Required by Date</th>
                    <th>Delivery Address</th>
                    <th>Site Contact Name and Number</th>
                  </tr>
                </thead>
                <tbody>
                  {orderDetails.result && orderDetails.result.deliveryDetails ? (
                    <tr>
                      <td>
                        <div>
                          <img src={Calendar} alt="Calendar" className={Styles.iconimg} />
                          {orderDetails.result.deliveryDetails.deliveryDate}
                        </div>
                      </td>

                      <td>
                        <div>
                          <img src={Location} alt="Delivery Address" className={Styles.iconimg} />
                          {`Building: ${orderDetails.result.deliveryDetails.deliveryAddress.siteDetail.buildingNumber},Floor: ${orderDetails.result.deliveryDetails.deliveryAddress.siteDetail.floorNumber},Room: ${orderDetails.result.deliveryDetails.deliveryAddress.siteDetail.roomNumber},Zone: ${orderDetails.result.deliveryDetails.deliveryAddress.siteDetail.zone},${orderDetails.result.deliveryDetails.deliveryAddress.deliveryAddress}, ${orderDetails.result.deliveryDetails.deliveryAddress.country.city.cityName}, ${orderDetails.result.deliveryDetails.deliveryAddress.country.countryName}`}
                        </div>
                      </td>

                      <td>
                        <div>
                          <img src={Contact} alt="Site Contact Number" className={Styles.iconimg} />
                          <p>
                            {
                              orderDetails.result.deliveryDetails.siteContactNameAndNumber
                                .siteContactName
                            }
                          </p>
                        </div>
                        <div>
                          <img src={Phone} alt="Conatact Number" className={Styles.iconimg} />
                          <p>
                            +44{" "}
                            {
                              orderDetails.result.deliveryDetails.siteContactNameAndNumber
                                .siteContactNumber
                            }
                          </p>
                        </div>
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            </div>
            <div className={Styles.instructions}>
              {orderDetails.result && orderDetails.result.deliveryDetails ? (
                <div>
                  <h1 className={Styles.subHeading}>Delivery Instructions</h1>
                  <div>{orderDetails.result.deliveryDetails.deliveryInstructions}</div>
                </div>
              ) : null}
            </div>

            <div className={Styles.orderDetails}>
              <h1 className={Styles.subHeading}>Order Details</h1>
              <table className={Styles.ordertable}>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Item name</th>
                    <th>Quantity</th>
                  </tr>
                </thead>
                {orderDetails.result &&
                orderDetails.result.itemsDetails &&
                orderDetails.result.itemsDetails.content ? (
                  <tbody>
                    {orderDetails.result.itemsDetails.content.map((item, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{item.itemName}</td>
                        <td>{item.quantity}</td>
                      </tr>
                    ))}
                  </tbody>
                ) : null}
              </table>
            </div>
            <div style={{ float: "right" }} className={Styles.btnform}>
              <Button
                key="buy"
                size="large"
                style={{ margin: "5px 20px" }}
                onClick={this.cancelcart}
              >
                Cancel
              </Button>

              <Button
                type="primary"
                key="console"
                size="large"
                style={{ margin: "5px 0px", font: "Roboto", width: "140px" }}
                onClick={this.confirmOrder}
              >
                Confirm Order
              </Button>
            </div>
          </Content>
          <div></div>
        </Layout>
      </MepLayout>
    );
  }
}

OrderSummary.propTypes = {
  orderDetails: PropTypes.shape({
    result: PropTypes.object
  }).isRequired,
  userOrder: PropTypes.func.isRequired,
  userStatus: PropTypes.func.isRequired,
  status: PropTypes.func.isRequired,
  initiateCart: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  orderDetails: state.orderSummary.details,
  orderStatus: state.orderSummary.orderStatus,
  placeOrderInfo: state.placeOrder.info,
  userInfo: state.user.info,
  cartId: state.user.cartId.result
});

const mapDispatchToProps = dispatch => ({
  userOrder: orderId => dispatch(getUserOrder(orderId)),
  userStatus: () => dispatch(userStatusLookUp({})),
  status: (orderId, statusId) => dispatch(userStatusId(orderId, statusId)),
  initiateCart: userId => dispatch(initiateCart({ userId })),
  getCartSavedListData: (event, currentPage, pageSize, cartId) =>
    dispatch(
      getCartSavedListAction({
        userId: event ? event.userId : null,
        cartId,
        currentPage: currentPage,
        pageSize: pageSize
      })
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderSummary);
