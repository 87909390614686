import { combineReducers } from "redux";

import * as actions from "./orderSummaryAction";

const initialState = { result: null, error: null };

const details = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_ORDER_SUMMARY:
      return { ...state };
    case actions.GET_ORDER_SUMMARY_SUCCESS:
      return { result: action.result, error: null };
    case actions.GET_ORDER_SUMMARY_ERROR:
      return { result: null, error: action.error };
    default:
      return { ...state };
  }
};

const orderStatus = (state = initialState, action) => {
  switch (action.type) {
    case actions.POST_ORDER_STATUS:
      return { ...state };
    case actions.POST_ORDER_STATUS_SUCCESS:
      return { result: action.result, error: null };
    case actions.POST_ORDER_STATUS_ERROR:
      return { result: null, error: action.error };
    default:
      return { ...state };
  }
};

const orderStatusId = (state = initialState, action) => {
  switch (action.type) {
    case actions.PRE_ORDER_STATUS:
      return { ...state };
    case actions.PRE_ORDER_STATUS_SUCCESS:
      return { result: action.result, error: null };
    case actions.PRE_ORDER_STATUS_ERROR:
      return { result: null, error: action.error };
    default:
      return { ...state };
  }
};

const cartId = (state = initialState, action) => {
  switch (action.type) {
    case actions.POST_INITIATE_CART:
      return { ...state, fetching: true };
    case actions.POST_INITIATE_CART_SUCCESS:
      return { result: action.result.cartId, error: null, fetching: false };
    case actions.POST_INITIATE_CART_ERROR:
      return { result: null, error: action.error, fetching: false };
    default:
      return { ...state };
  }
};

export default combineReducers({
  details,
  orderStatus,
  orderStatusId,
  cartId
});
