import React from "react";
import { Link } from "react-router-dom";
import { Result, Button } from "antd";
class ErrorHandler extends React.PureComponent {
  chooseErrorHandler = error => {
    switch (error) {
      case "pageNotFound":
        return (
          <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={
              <Button type="primary">
                <Link to="/">Back Home</Link>
              </Button>
            }
          />
        );
      case "itemNotFound":
        return <Result title="No Items Found." />;
      case "serverError":
        return (
          <Result
            status="500"
            title="500"
            subTitle="Sorry, the server is wrong."
            extra={<Button type="primary">Back Home</Button>}
          />
        );
      case "success":
        return (
          <Result
            status="success"
            title="Successfully Ordered!"
            subTitle="Order number: 2017182818828182881"
            extra={[
              <Button type="primary" key="console">
                Go Home
              </Button>
            ]}
          />
        );
      case "warning":
        return (
          <Result
            status="warning"
            title="There are some problems with your operation."
            extra={
              <Button type="primary" key="console">
                Go Home
              </Button>
            }
          />
        );
      default:
        break;
    }
  };
  render() {
    return <>{this.chooseErrorHandler(this.props.errorCase)}</>;
  }
}

export default ErrorHandler;
