import React from "react";
import { Select, Row, Col } from "antd";

import { Input } from "antd";
import Styles from "./collectfromstore.module.css";

const { Option } = Select;

const { TextArea } = Input;

class collectfromstore extends React.Component {
  handleChange = value => {};
  render() {
    return (
      <div className={Styles.collectfrom}>
        <div>
          <Row>
            <p className={Styles.title}>Choose nearest Store to you</p>
            <Col span={12}>
              <Select
                defaultValue="Wirehouse 01"
                style={{ width: "90%", color: "rgba(0, 0, 0, 0.26)" }}
                onChange={this.handleChange}
              >
                <Option value="1">Store-1</Option>
                <Option value="2">Store-2</Option>
                <Option value="3">Store-3</Option>
              </Select>
            </Col>
          </Row>
        </div>
        <Row>
          <p className={Styles.formtitle}>Delivery Instructions</p>
          <div>
            <Row>
              <Col span={24} style={{ height: "100px" }}>
                <TextArea rows={4} />
              </Col>
            </Row>
          </div>
        </Row>
      </div>
    );
  }
}
export default collectfromstore;
