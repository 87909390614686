import {
  watchGetUser,
  watchGetUserAddress,
  watchPostUserLogin,
  watchGetUserBuilding,
  watchGetUserFloor,
  watchGetUserRoom,
  watchGetUserZone,
  watchInitiateCart
} from "./loginWatchers";

export default [
  watchGetUser,
  watchGetUserAddress,
  watchPostUserLogin,
  watchGetUserBuilding,
  watchGetUserFloor,
  watchGetUserRoom,
  watchGetUserZone,
  watchInitiateCart
];
