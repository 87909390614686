import { takeLatest, call } from "redux-saga/effects";

import * as workers from "./orderNowWorkers";
import { cartListTypes } from "./orderNowTypes";

export default function* watchGetCartList() {
  yield takeLatest(cartListTypes.GET_PRODUCT_LIST, function*(loadAction) {
    yield call(workers.fetchCartProductListDataWorker, loadAction.payload);
  });
  yield takeLatest(cartListTypes.GET_CART_LIST_ITEMS, function*(loadAction) {
    yield call(workers.fetchCartListItemsDataWorker, loadAction.payload);
  });
  yield takeLatest(cartListTypes.GET_CART_LIST, function*(loadAction) {
    yield call(workers.fetchCartSavedListDataWorker, loadAction.payload);
  });
  yield takeLatest(cartListTypes.ADD_CART_LIST_ITEMS, function*(loadAction) {
    yield call(workers.addCartListItemsDataWorker, loadAction.payload);
  });
  yield takeLatest(cartListTypes.DELETE_CART_LIST_ITEMS, function*(loadAction) {
    yield call(workers.deleteCartListItemsDataWorker, loadAction.payload);
  });
}
