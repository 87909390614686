export const POST_USER_LOGIN = "POST_USER_LOGIN";
export const POST_USER_LOGIN_SUCCESS = "POST_USER_LOGIN_SUCCESS";
export const POST_USER_LOGIN_ERROR = "POST_USER_LOGIN_ERROR";

export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_ERROR = "GET_USER_ERROR";

export const GET_USER_ADDRESS = "GET_USER_ADDRESS";
export const GET_USER_ADDRESS_SUCCESS = "GET_USER_ADDRESS_SUCCESS";
export const GET_USER_ADDRESS_ERROR = "GET_USER_ADDRESS_ERROR";

export const GET_USER_BUILDING = "GET_USER_BUILDING";
export const GET_USER_BUILDING_SUCCESS = "GET_USER_BUILDING_SUCCESS";
export const GET_USER_BUILDING_ERROR = "GET_USER_BUILDING_ERROR";

export const GET_USER_FLOOR = "GET_USER_FLOOR";
export const GET_USER_FLOOR_SUCCESS = "GET_USER_FLOOR_SUCCESS";
export const GET_USER_FLOOR_ERROR = "GET_USER_FLOOR_ERROR";

export const GET_USER_ROOM = "GET_USER_ROOM";
export const GET_USER_ROOM_SUCCESS = "GET_USER_ROOM_SUCCESS";
export const GET_USER_ROOM_ERROR = "GET_USER_ROOM_ERROR";

export const GET_USER_ZONE = "GET_USER_ZONE";
export const GET_USER_ZONE_SUCCESS = "GET_USER_ZONE_SUCCESS";
export const GET_USER_ZONE_ERROR = "GET_USER_ZONE_ERROR";

export const POST_INITIATE_CART = "POST_INITIATE_CART";
export const POST_INITIATE_CART_SUCCESS = "POST_INITIATE_CART_SUCCESS";
export const POST_INITIATE_CART_ERROR = "POST_INITIATE_CART_ERROR";

export const RESET_USER_FLOOR = "RESET_USER_FLOOR";
export const RESET_USER_ROOM = "RESET_USER_ROOM";

export const postUserAction = payload => ({
  type: POST_USER_LOGIN,
  payload
});

export const getUserAction = payload => ({
  type: GET_USER,
  payload
});

export const getUserAddressAction = payload => ({
  type: GET_USER_ADDRESS,
  payload
});

export const getUserBuildingAction = (payload = null) => ({
  type: GET_USER_BUILDING,
  payload
});

export const getUserfloorAction = payload => ({
  type: GET_USER_FLOOR,
  payload
});

export const getUserRoomAction = payload => ({
  type: GET_USER_ROOM,
  payload
});

export const getUserZoneAction = payload => ({
  type: GET_USER_ZONE,
  payload
});

export const initiateCartAction = payload => ({
  type: POST_INITIATE_CART,
  payload
});

export const resetUserFloorAction = () => ({
  type: RESET_USER_FLOOR
});

export const resetUserRoomAction = () => ({
  type: RESET_USER_ROOM
});
