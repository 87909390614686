import { takeLatest, call } from "redux-saga/effects";

import * as actions from "./forgetAction";
import forgetWorkers from "./forgetWorkers";

// watch for every purchase completion, and start the purchaseFlow
export default function* watchResetPassword() {
  yield takeLatest(actions.RESET_PASSWORD, function*(loadAction) {
    yield call(forgetWorkers, loadAction.payload);
  });
}
