import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { loadingBarReducer } from "react-redux-loading-bar";

import homeReducer from "./pages/home/homeReducer";
import cartListReducer from "./pages/order-now/orderNowReducer";
import loginReducer from "./pages/login/loginReducer";
import forgetReducer from "./pages/forget-password/forgetReducer";
import orderSummaryReducer from "./pages/order-summary/orderSummaryReducer";
import placeOrderReducer from "./pages/delivery-channel/deliveryChannelAndAddressReducer";
import myOrderReducer from "./pages/myorders/myOrderReducer";

export default history =>
  combineReducers({
    router: connectRouter(history),
    loadingBar: loadingBarReducer,
    cart: cartListReducer,
    home: homeReducer,
    user: loginReducer,
    reset: forgetReducer,
    placeOrder: placeOrderReducer,
    orderSummary: orderSummaryReducer,
    myOrderDetails: myOrderReducer
  });
