import { call, put } from "redux-saga/effects";
import axios from "axios";

import { cartListTypes } from "./orderNowTypes";
import config from "../../config/appConfig";

const fetchCartProductListData = id => {
  return axios
    .get(`${config.apiBaseUrl}${config.getCartProductDetailsEndpoint}`)
    .then(response => {
      if (response.status > 399) {
        throw Error(response.statusText);
      }
      return response;
    })
    .catch(error => {
      throw new Error(error);
    });
};

const fetchCartListData = payload => {
  const url = `${config.apiBaseUrl}${config.getSavedCartItemsDetailsEndpoint}?userId=${payload.userId}&cartId=${payload.cartId}&size=${payload.pageSize}&page=0`;
  return axios
    .get(`${url}`)
    .then(response => {
      // handle success
      if (response.status > 399) {
        throw Error(response.statusText);
      }
      return response;
    })
    .catch(error => {
      // handle error
      throw new Error(error);
    });
};

const searchItemList = item => {
  const url =
    item.type === "search"
      ? `${config.apiBaseUrl}${config.getCartItemDetailsByCategoryNameEndpoint}?itemName=${
          item.value
        }&size=${item.pageSize}&page=${item.currentPage - 1}`
      : `${config.apiBaseUrl}${config.getCartItemDetailsByIdEndpoint}?categoryId=${
          item.value
        }&size=${item.pageSize}&page=${item.currentPage - 1}`;
  return axios
    .get(`${url}`)
    .then(response => {
      if (response.status > 399) {
        throw Error(response.statusText);
      }
      return response;
    })
    .catch(error => {
      throw new Error(error);
    });
};

const deleteItemFromCart = item => {
  const url = `${config.apiBaseUrl}${config.deleteItemsFromCartEndpoint}`;

  return axios
    .post(url, item)
    .then(response => {
      // handle success
      if (response.status > 399) {
        throw Error(response.statusText);
      }
      return response;
    })
    .catch(error => {
      // handle error
      throw new Error(error);
    });
};
const addItemToCart = item => {
  const url = `${config.apiBaseUrl}${config.addItemsToCartEndpoint}`;
  return axios
    .post(url, item)
    .then(response => {
      // handle success
      if (response.status > 399) {
        throw Error(response.statusText);
      }
      return response;
    })
    .catch(error => {
      // handle error
      throw new Error(error);
    });
};

export function* fetchCartProductListDataWorker() {
  try {
    const result = yield call(fetchCartProductListData);
    yield put({
      result,
      error: null,
      type: cartListTypes.GET_PRODUCT_LIST_SUCCESS
    });
  } catch (error) {
    yield put({
      result: null,
      error,
      type: cartListTypes.GET_PRODUCT_LIST_ERROR
    });
  }
}
export function* fetchCartSavedListDataWorker(payload) {
  try {
    const result = yield call(fetchCartListData, payload);
    yield put({
      result,
      error: null,
      type: cartListTypes.GET_CART_LIST_SUCCESS
    });
  } catch (error) {
    yield put({
      result: null,
      error,
      type: cartListTypes.GET_CART_LIST_ERROR
    });
  }
}

export function* fetchCartListItemsDataWorker(payload) {
  try {
    const result = yield call(searchItemList, payload);
    yield put({
      result,
      error: null,
      type: cartListTypes.GET_CART_LIST_ITEMS_SUCCESS
    });
  } catch (error) {
    yield put({
      result: null,
      error,
      type: cartListTypes.GET_CART_LIST_ITEMS_ERROR
    });
  }
}
export function* addCartListItemsDataWorker(payload) {
  try {
    const result = yield call(addItemToCart, payload);
    yield put({
      result,
      error: null,
      type: cartListTypes.ADD_CART_LIST_ITEMS_SUCCESS
    });
  } catch (error) {
    yield put({
      result: null,
      error,
      type: cartListTypes.ADD_CART_LIST_ITEMS_ERROR
    });
  }
}
export function* deleteCartListItemsDataWorker(payload) {
  try {
    const result = yield call(deleteItemFromCart, payload);
    yield put({
      result,
      error: null,
      type: cartListTypes.DELETE_CART_LIST_ITEMS_SUCCESS
    });
  } catch (error) {
    yield put({
      result: null,
      error,
      type: cartListTypes.DELETE_CART_LIST_ITEMS_ERROR
    });
  }
}
