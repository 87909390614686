import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Form, Input, Button, Checkbox, Spin, Icon, message } from "antd";

import { postUserAction, getUserAction } from "./loginAction";
import auth from "../../utils/auth";

import Styles from "./login.module.css";

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      password: ""
    };
  }

  componentDidMount() {
    if (auth.getAuthToken() !== "") {
    }
    const userName = localStorage.getItem("userName");
    if (userName) {
      this.setState({ userName });
    } else {
      this.setState({ userName: "" });
    }
    this.loginTrigger = false;
    this.error = "";
  }
  componentWillReceiveProps() {
    if (this.props.userLogin.result == null && this.loginTrigger) {
      this.error = "Invalid User Id and Password";
    } else {
    }
  }
  componentDidUpdate(prevProps, prevState) {
    this.error = "";
    if (prevProps.userLogin.result !== this.props.userLogin.result) {
      if (this.props.userLogin.result) {
        auth.setAuthToken(this.props.userLogin.result);
        this.props.getUser(this.props.userLogin.result);
        this.props.history.push("/home");
      }
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div className={Styles.form}>
        <Form onSubmit={this.handleSubmit}>
          <div className={Styles.formName}>
            <span>Login</span>
            {this.error ? (
              <div className={Styles.error}>&#10071;Invalid Username Or Password</div>
            ) : (
              ""
            )}
          </div>
          <div className={Styles.formInputarea}>
            <Form.Item className={Styles.formInput}>
              <label className={Styles.labeling}>User name</label>
              {getFieldDecorator("userName", {
                rules: [{ required: true, message: "Please input your userName" }],
                initialValue: this.state.userName
              })(
                <Input
                  size="large"
                  name="userName"
                  id="user"
                  onChange={this.handleUsernameChange}
                  onClick={this.value}
                ></Input>
              )}
            </Form.Item>

            <Form.Item className={Styles.formInput}>
              <label className={Styles.labeling}>Password</label>
              {getFieldDecorator("password", {
                rules: [{ required: true, message: "Password is mandatory" }],
                initialValue: this.state.password
              })(
                <Input
                  size="large"
                  name="password"
                  type="password"
                  onChange={this.handlePasswordChange}
                ></Input>
              )}
            </Form.Item>
          </div>
          <Form.Item className={Styles.submit}>
            {getFieldDecorator("remember", {
              valuePropName: "checked",
              initialValue: false
            })(
              <Checkbox onClick={this.rememberMe}>
                <span className={Styles.remember}>Remember me</span>
              </Checkbox>
            )}
            <Link className={Styles.loginFormForgot} to={"/forget-password"}>
              Forgot password
            </Link>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              className={Styles.loginFormButton}
            >
              {this.props.userData.fetching ? "SIGNING IN" : "SIGN IN"}{" "}
              {this.props.userData.fetching ? (
                <Spin
                  indicator={
                    <Icon
                      type="loading"
                      style={{ color: "#ddd", position: "relative", left: "10px" }}
                      spin
                    />
                  }
                />
              ) : (
                ""
              )}
            </Button>{" "}
            <div className={Styles.signUp}>
              <span>
                Don't have account? <Link to={""}>Sign Up</Link>
              </span>
            </div>
          </Form.Item>
        </Form>
      </div>
    );
  }

  handleUsernameChange = e => {
    this.setState({ userName: e.target.value });
  };

  handlePasswordChange = e => {
    this.setState({ password: e.target.value });
  };

  rememberMe = e => {
    const userName = this.state.userName;

    if (userName) {
      localStorage.setItem("userName", userName);
    }
  };

  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      const user = { userName: this.state.userName, password: this.state.password };
      this.props.postLogin(user);
      this.loginTrigger = true;
      this.props.userLogin.result = 0;
      this.error = "";
    });
  };
}

LoginForm.propTypes = {
  userData: PropTypes.shape({
    result: PropTypes.number || PropTypes.object,
    fetching: PropTypes.bool
  }).isRequired,
  getUser: PropTypes.func.isRequired,
  postLogin: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    userData: state.user.info,
    userLogin: state.user.login
  };
};

const mapDispatchToProps = dispatch => ({
  postLogin: user => dispatch(postUserAction(user)),
  getUser: userId => dispatch(getUserAction(userId))
});

const Login = Form.create({ name: "normal_login" })(LoginForm);
export default connect(mapStateToProps, mapDispatchToProps)(Login);
