import { call, put, take } from "redux-saga/effects";
import axios from "axios";
import { showLoading, hideLoading } from "react-redux-loading-bar";

import * as actions from "./loginAction";
import config from "../../config/appConfig";
import auth from "../../utils/auth";

const callToAPI = async (method, url, data = {}) => {
  try {
    const response = await axios({
      method: method,
      url: url,
      data
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export function* postUserLoginWorker(payload) {
  try {
    yield put(showLoading());
    const url = `${config.apiBaseUrl}${config.loginEndpoint}?userName=${payload.userName}&password=${payload.password}`;
    const result = yield call(callToAPI, "POST", url);

    yield put({
      result: result,
      error: null,
      type: actions.POST_USER_LOGIN_SUCCESS
    });
  } catch (error) {
    yield put({
      result: null,
      error: error,
      type: actions.POST_USER_LOGIN_ERROR
    });
  } finally {
    yield put(hideLoading());
  }
}

export function* getUserWorker(payload) {
  try {
    yield put(showLoading());
    const url = `${config.apiBaseUrl}${config.getUserDetailsEndpont}?userId=${payload}`;
    const result = yield call(callToAPI, "GET", url);
    yield put({
      result,
      error: null,
      type: actions.GET_USER_SUCCESS
    });
  } catch (error) {
    yield put({
      result: null,
      error: error,
      type: actions.GET_USER_ERROR
    });
  } finally {
    yield put(hideLoading());
  }
}

export function* getUserAddressWorker(payload) {
  try {
    yield put(showLoading());
    const url = `${config.apiBaseUrl}${config.getUserAddressEndpoint}?userId=${payload}`;
    const result = yield call(callToAPI, "GET", url);
    yield put({
      result,
      error: null,
      type: actions.GET_USER_ADDRESS_SUCCESS
    });
  } catch (error) {
    yield put({
      result: null,
      error: error,
      type: actions.GET_USER_ADDRESS_ERROR
    });
  } finally {
    yield put(hideLoading());
  }
}

export function* getUserBuildingWorker(payload) {
  try {
    yield put(showLoading());

    const userId = auth.getAuthToken();
    const siteUrl = `${config.apiBaseUrl}${config.getUserAddressEndpoint}?userId=${userId}`;
    const siteDetails = yield call(callToAPI, "GET", siteUrl);

    if (!siteDetails) {
      throw new Error("Site details missing for the user");
    }
    const siteId = siteDetails[0].siteId;
    const url = `${config.apiBaseUrl}${config.getBuildingEndpoint}?siteId=${siteId}`;

    const result = yield call(callToAPI, "GET", url);
    yield put({
      result,
      error: null,
      type: actions.GET_USER_BUILDING_SUCCESS
    });
  } catch (error) {
    yield put({
      result: null,
      error: error,
      type: actions.GET_USER_BUILDING_ERROR
    });
  } finally {
    yield put(hideLoading());
  }
}

export function* getUserFloorWorker(payload) {
  try {
    yield put(showLoading());
    const url = `${config.apiBaseUrl}${config.getFloorEndpoint}?buildingId=${payload}`;
    const result = yield call(callToAPI, "GET", url);

    yield put({
      result,
      error: null,
      type: actions.GET_USER_FLOOR_SUCCESS
    });
  } catch (error) {
    yield put({
      result: null,
      error: error,
      type: actions.GET_USER_FLOOR_ERROR
    });
  } finally {
    yield put(hideLoading());
  }
}

export function* getUserRoomWorker(payload) {
  try {
    yield put(showLoading());
    const url = `${config.apiBaseUrl}${config.getRoomListEndpoint}?floorId=${payload}`;
    const result = yield call(callToAPI, "GET", url);

    yield put({
      result,
      error: null,
      type: actions.GET_USER_ROOM_SUCCESS
    });
  } catch (error) {
    yield put({
      result: null,
      error: error,
      type: actions.GET_USER_ROOM_ERROR
    });
  } finally {
    yield put(hideLoading());
  }
}

export function* getUserZoneWorker(payload) {
  try {
    yield put(showLoading());

    const userId = auth.getAuthToken();
    const siteUrl = `${config.apiBaseUrl}${config.getUserAddressEndpoint}?userId=${userId}`;
    const siteDetails = yield call(callToAPI, "GET", siteUrl);
    // throw error if site details is missing
    if (!siteDetails) {
      throw new Error("Site details missing for the user");
    }
    const countryId = siteDetails[0].country.countryId;

    const url = `${config.apiBaseUrl}${config.getZoneListEndpoint}?countryId=${countryId}`;
    const result = yield call(callToAPI, "GET", url);

    yield put({
      result,
      error: null,
      type: actions.GET_USER_ZONE_SUCCESS
    });
  } catch (error) {
    yield put({
      result: null,
      error: error,
      type: actions.GET_USER_ZONE_ERROR
    });
  } finally {
    yield put(hideLoading());
  }
}

export function* initiateCartWorker(payload) {
  try {
    yield put(showLoading());
    const url = `${config.apiBaseUrl}${config.initiateCartEndpoint}`;
    const result = yield call(callToAPI, "POST", url, payload);

    yield put({
      result,
      error: null,
      type: actions.POST_INITIATE_CART_SUCCESS
    });
  } catch (error) {
    yield put({
      result: null,
      error: error,
      type: actions.POST_INITIATE_CART_ERROR
    });
  } finally {
    yield put(hideLoading());
  }
}
