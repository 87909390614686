import { takeLatest, call } from "redux-saga/effects";

import * as actions from "./orderSummaryAction";
import { getOrderSummaryWorker } from "./orderSummaryWorkers";
import { updateOrderStatus, updateOrderStatusId, initiateCartWorker } from "./orderSummaryWorkers";

export function* watchGetOrderSummary() {
  yield takeLatest(actions.GET_ORDER_SUMMARY, function*(loadAction) {
    yield call(getOrderSummaryWorker, loadAction.payload);
  });
}

export function* watchOrderStatus() {
  yield takeLatest(actions.POST_ORDER_STATUS, function*(loadAction) {
    yield call(updateOrderStatus, loadAction.payload);
  });
}

export function* watchOrderStatusID() {
  yield takeLatest(actions.PRE_ORDER_STATUS, function*(loadAction) {
    yield call(updateOrderStatusId, loadAction.payload, loadAction.statusId);
  });
}

export function* watchInitiateCart() {
  yield takeLatest(actions.POST_INITIATE_CART, function*(loadAction) {
    yield call(initiateCartWorker, loadAction.payload);
  });
}
