import React from "react";

import utils from "../../utils/auth";

class Logout extends React.Component {
  componentDidMount() {
    utils.removeToken("authToken");
    this.props.history.push("/login");
  }
  render() {
    return null;
  }
}

export default Logout;
