import React, { PureComponent } from "react";
import { Row, Col, Card, Button, Icon, Input, notification, Spin } from "antd";
import "./cart-item.css";
import noImage from "../../../assets/imagenot-available.png";
import deleleImg from "../../../assets/bitmap@2x.png";
export default class CartItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      toggleButton: false,
      quantityCount: 0,
      disableBtn: false
    };
    this.toggleFunc = this.toggleFunc.bind(this);
    this.addOrRemoveQuantity = this.addOrRemoveQuantity.bind(this);
  }
  componentDidMount() {
    if (this.props.cartItem.numberOfUnits || this.props.cartedQuantity) {
      this.setState({ toggleButton: !this.state.toggleButton });
      this.setState({
        quantityCount: this.props.cartedQuantity
          ? this.props.cartedQuantity
          : this.props.cartItem.numberOfUnits
      });
    }
  }
  componentDidUpdate() {
    if (this.state.quantityCount <= 0 && this.state.toggleButton) {
      // this.toggleFunc();
      this.setState({ toggleButton: !this.state.toggleButton });
    }
    if (
      this.state.quantityCount ===
      Number(
        this.props.cartItem.quantity
          ? this.props.cartItem.quantity
          : this.props.cartItem.totalUnitsAvailable
      )
    ) {
      this.setState({ disableBtn: true });
    } else {
      this.setState({ disableBtn: false });
    }
  }

  addOrRemoveQuantity = type => {
    if (type === "Increment") {
      this.setState({ quantityCount: this.state.quantityCount + 1 }, () =>
        this.addedItemToCart(this.props.cartItem, this.state.quantityCount)
      );
    } else {
      this.setState({ quantityCount: this.state.quantityCount - 1 }, () => {
        if (this.state.quantityCount <= 0) {
          this.props.deleteItemFromCart(
            this.props.cartItem.ItemId ? this.props.cartItem.ItemId : this.props.cartItem.itemId
          );
        } else {
          this.addedItemToCart(this.props.cartItem, this.state.quantityCount);
        }
      });
    }
    if (
      this.state.quantityCount ===
      Number(
        this.props.cartItem.quantity
          ? this.props.cartItem.quantity
          : this.props.cartItem.totalUnitsAvailable
      )
    ) {
      this.setState({ disableBtn: true });
    } else {
      this.setState({ disableBtn: false });
    }
  };
  toggleFunc = () => {
    this.setState({ toggleButton: !this.state.toggleButton });
    if (this.state.quantityCount <= 0) {
      this.setState({ quantityCount: 1 }, () =>
        this.addedItemToCart(this.props.cartItem, this.state.quantityCount)
      );
    }
  };
  getInputValue = e => {
    const countValue = Number(e.target.value);
    this.setState({ quantityCount: countValue }, () => {
      if (this.state.quantityCount <= 0) {
        this.props.deleteItemFromCart(
          this.props.cartItem.ItemId ? this.props.cartItem.ItemId : this.props.cartItem.itemId
        );
      } else {
        this.props.addItemToCart(
          this.props.cartItem.ItemId ? this.props.cartItem.ItemId : this.props.cartItem.itemId,
          this.state.quantityCount
        );
      }
      if (
        this.state.quantityCount >
        Number(
          this.props.cartItem.quantity
            ? this.props.cartItem.quantity
            : this.props.cartItem.totalUnitsAvailable
        )
      ) {
        notification["warning"]({
          message: "Less Quantity",
          description: "You have added more than the available quantity."
        });
        this.setState({ quantityCount: 1 }, () =>
          this.props.addItemToCart(
            this.props.cartItem.ItemId ? this.props.cartItem.ItemId : this.props.cartItem.itemId,
            this.state.quantityCount
          )
        );
      } else {
        this.setState({ disableBtn: false });
      }
    });
  };
  addedItemToCart = item => {
    this.props.addItemToCart(item.ItemId ? item.ItemId : item.itemId, this.state.quantityCount);
  };
  deletedItemFromCart = item => {
    this.props.deleteItemFromCart(item.ItemId ? item.ItemId : item.itemId);
  };
  render() {
    const ButtonGroup = Button.Group;
    const cartItem = this.props.cartItem;
    const enableDelete = this.props.deleteIcon;
    const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
    return (
      <>
        {}
        <Card style={{ width: "auto" }} hoverable="true">
          <Row type="flex" justify="center">
            <Col span={2}>
              <div className="img-container">
                <img
                  className="img-size"
                  src={cartItem.imageUrl ? cartItem.imageUrl : noImage}
                  alt="items"
                />
              </div>
            </Col>
            <Col span={15}>
              <div className="itemsdetails">
                <span className="White-Upvc-Threaded">{cartItem.itemName}</span>
                <br />
                <span className="Qty-Copy">
                  Available Unit/Qty:{" "}
                  <span className="Units">
                    {cartItem.quantity <= 0 ? "Out of Stock" : cartItem.quantity}
                    {cartItem.totalUnitsAvailable <= 0
                      ? "Out of Stock"
                      : cartItem.totalUnitsAvailable}
                  </span>
                </span>
              </div>
            </Col>
            <Col span={7}>
              {enableDelete ? (
                // <Icon
                //   type="delete"
                //   className="cart_delete_icon"
                //   onClick={e => this.deletedItemFromCart(cartItem)}
                // />
                <img
                  src={deleleImg}
                  alt="delete"
                  className="cart_delete_icon"
                  onClick={e => this.deletedItemFromCart(cartItem)}
                ></img>
              ) : (
                ""
              )}
              {this.state.toggleButton && this.state.quantityCount >= 1 ? (
                <ButtonGroup>
                  <Button onClick={e => this.addOrRemoveQuantity("Decrement")} className="btngrp">
                    <Icon type="minus" />
                  </Button>
                  {this.props.spinner ? (
                    <Spin indicator={antIcon} className="spinner-div" />
                  ) : (
                    <Input
                      className="item-count-input"
                      value={this.state.quantityCount}
                      onChange={e => this.getInputValue(e)}
                      // onBlur={e => this.getInputValue(e)}
                      maxLength={25}
                      disabled={
                        cartItem.quantity <= 0 || cartItem.totalUnitsAvailable <= 0 ? true : false
                      }
                    />
                  )}

                  <Button
                    onClick={e => this.addOrRemoveQuantity("Increment")}
                    className="btngrp"
                    disabled={this.state.disableBtn}
                  >
                    <Icon type="plus" />
                  </Button>
                </ButtonGroup>
              ) : (
                <Button
                  type="primary"
                  className="add_btn"
                  onClick={e => this.toggleFunc()}
                  disabled={
                    cartItem.quantity <= 0 || cartItem.totalUnitsAvailable <= 0 ? true : false
                  }
                >
                  <span className="add-btn">ADD</span>
                </Button>
              )}
            </Col>
          </Row>
        </Card>
      </>
    );
  }
}
