import { call, put } from "redux-saga/effects";
import axios from "axios";
import { showLoading, hideLoading } from "react-redux-loading-bar";

import * as actions from "./myOrderAction";
import config from "../../config/appConfig";

const callToAPI = async (method, url, data = {}) => {
  try {
    const response = await axios({
      method,
      url,
      data
    });

    const details = response.data;
    console.log(details);
    return { details };
  } catch (error) {
    throw error;
  }
};

export function* getMyOrder(payload) {
  if (payload !== "") {
    console.log(payload);
    try {
      const size = 100;
      const page = 0;
      yield put(showLoading());

      const url = `${config.apiBaseUrl}${config.getMyOrders}?userId=${payload}&size=${size}&page=${page}`;
      const result = yield call(callToAPI, "GET", url);

      yield put({
        result,
        error: null,
        type: actions.MY_ORDER_SUCCESS
      });
    } catch (error) {
      yield put({
        result: null,
        error: error,
        type: actions.MY_ORDER_ERROR
      });
    } finally {
      yield put(hideLoading());
    }
  }
}
