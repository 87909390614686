import React from "react";
import { Icon, Radio } from "antd";
import Styles from "./tab.module.css";
const tabStyles = {
  radionBtn: {
    borderRadius: 0,
    border: 0,
    borderBottom: "4px solid #dddddd",
    outline: "none",
    width: "50%",
    backgroundColor: "#fff",
    boxShadow: "none",
    boxSizing: "border-box",
    height: "60px",
    position: "relative"
  },
  active: {
    borderBottom: "4px solid #f9a426",
    backgroundColor: "#e1f2fe"
  }
};
class Tab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedModeOfDeliveryId: this.props.data[0].lookUpValueId
    };
  }

  changeModeOfDelivery = e => {
    this.setState({
      selectedModeOfDeliveryId: e.target.value
    });
  };
  render() {
    const { selectedModeOfDeliveryId } = this.state;
    const { data } = this.props;
    return (
      <div className={Styles.box}>
        <Radio.Group
          onChange={e => this.changeModeOfDelivery(e)}
          value={selectedModeOfDeliveryId}
          className={Styles.radioGroup}
        >
          {data.map((item, index) => (
            <Radio.Button
              key={index}
              value={item.lookUpValueId}
              className={Styles.radioGroupBtn}
              style={
                selectedModeOfDeliveryId === item.lookUpValueId
                  ? { ...tabStyles.radionBtn, ...tabStyles.active }
                  : { ...tabStyles.radionBtn }
              }
            >
              <div className={Styles.btnContent}>
                <img
                  src={require(`../../assets/${item.lookUpValueName
                    .split(" ")
                    .join("")
                    .toLowerCase()}.svg`)}
                  alt="on site"
                  height="32"
                  width="46"
                />{" "}
                <p style={{ paddingLeft: "16px" }}>{item.lookUpValueName}</p>
              </div>
            </Radio.Button>
          ))}
        </Radio.Group>
        <div>
          <button className={Styles.shopping} type="link" onClick={this.props.handleClick}>
            Continue Shopping
            <Icon className={Styles.arrow} type="arrow-right" />
          </button>
        </div>
      </div>
    );
  }
}
export default Tab;
