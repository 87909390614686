const ENV = process.env.NODE_ENV || "development";

let apiBaseUrl = "http://3.224.183.251:9000";

switch (ENV) {
  case "development":
    apiBaseUrl = "http://3.224.183.251:9000";
    break;
  case "qa":
    apiBaseUrl = "http://3.224.183.251:9000";
    break;
  case "production":
    apiBaseUrl = "http://3.224.183.251:9000";
    break;
  default:
    apiBaseUrl = "http://3.224.183.251:9000";
    break;
}

export default {
  apiBaseUrl,
  getCartProductDetailsEndpoint: "/mep-api/item/get-all-categories",
  getSavedCartItemsDetailsEndpoint: "/mep-api/cart/get-user-cart-detail-by-id",
  getCartItemDetailsByIdEndpoint: "/mep-api/item/get-items-by-category",
  getCartItemDetailsByCategoryNameEndpoint: "/mep-api/item/search",
  addItemsToCartEndpoint: "/mep-api/cart/add-item",
  deleteItemsFromCartEndpoint: "/mep-api/cart/remove-item",
  loginEndpoint: "/mep-api/loginlogout/login",
  getUserDetailsEndpont: "/mep-api/activity/get-user-details",
  getUserAddressEndpoint: "/mep-api/delivery/get-sites-for-login-user",
  getActivitiesEndpoint: "/mep-api/activity/get-activities",
  forgotPasswordEndpoint: "/mep-api/forgotpassword/forgot-password",
  getBuildingEndpoint: "/mep-api/delivery/get-building-list",
  getFloorEndpoint: "/mep-api/delivery/get-floor-list",
  getRoomListEndpoint: "/mep-api/delivery/get-room-list",
  getZoneListEndpoint: "/mep-api/delivery/get-zone-list",
  initiateCartEndpoint: "/mep-api/cart/init-cart",
  createOrderEndpoint: "/mep-api/order/create-order",
  getOrderSummaryEndpoint: "/mep-api/order/get-order-summaries",
  updateOrderStatus: "/mep-api/order/update-order-status",
  getOrderStatusLookupValues: "/mep-api/order/get-order-status-lookup-values",
  getMyOrders: "/mep-api/order/get-my-orders"
};
