import React, { Component } from "react";
import { Row, Col } from "antd";
// import mainLogo from "../../img/meh_logo.png";
import mainLogo from "../../assets/sgn-logo.png";
import Styles from "./login.module.css";
class MainLogo extends Component {
  render() {
    return (
      <Row>
        <Col lg={4}>
          <div className={Styles.clogo}>
            {/* <img className={Styles.companyLogo} src={mainLogo} alt="MainLogo"></img> */}
            <img
              // className={Styles.companyLogo}
              height="100%"
              width="80%"
              src={mainLogo}
              alt="MainLogo"
              style={{ filter: "brightness(0.3)" }}
            />
            <p style={{ color: "#1A1A1A", fontSize: "12px", marginTop: "10px", fontWeight: 600 }}>
              SGN Your gas. Our network.
            </p>
          </div>
        </Col>
      </Row>
    );
  }
}

export default MainLogo;
