import axios from "axios";
import { call, put } from "redux-saga/effects";
import { showLoading, hideLoading } from "react-redux-loading-bar";

import * as actions from "./homeAction";
import config from "../../config/appConfig";

const callToAPI = async (method, url, data = {}) => {
  try {
    const response = await axios({
      method: method,
      url: url,
      data: JSON.stringify(data)
    });
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export function* getActivitiesWorker() {
  try {
    yield put(showLoading());
    const url = `${config.apiBaseUrl}${config.getActivitiesEndpoint}`;
    const result = yield call(callToAPI, "GET", url);
    yield put({
      result,
      error: null,
      type: actions.GET_ACTIVITIES_SUCCESS
    });
  } catch (error) {
    yield put({
      result: null,
      error,
      type: actions.GET_ACTIVITIES_ERROR
    });
  } finally {
    yield put(hideLoading());
  }
}
