import { takeLatest, call } from "redux-saga/effects";

import * as actions from "./homeAction";
import { getActivitiesWorker } from "./homeWorkers";

// watch for every purchase completion, and start the purchaseFlow
export function* watchGetActivities() {
  yield takeLatest(actions.GET_ACTIVITIES, function*(loadAction) {
    yield call(getActivitiesWorker, loadAction.payload);
  });
}
