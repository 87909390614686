import { combineReducers } from "redux";
import * as actions from "./forgetAction";

const initialState = { result: null, error: null };

const forget = (state = initialState, action) => {
  switch (action.type) {
    case actions.RESET_PASSWORD:
      return { result: action.result, error: null };
    case actions.RESET_PASSWORD_SUCCESS:
      return { result: action.result, error: null, fetching: false };
    case actions.RESET_PASSWORD_ERROR:
      return { result: null, error: action.error };
    default:
      return { ...state };
  }
};

export default combineReducers({
  forget
});
