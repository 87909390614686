import { combineReducers } from "redux";
import * as actions from "./deliveryChannelAndAddressAction";

const initialState = { result: [], error: null, fetching: false };

const info = (state = initialState, action) => {
  switch (action.type) {
    case actions.POST_CREATE_ORDER:
      return { ...state, fetching: true };
    case actions.POST_CREATE_ORDER_SUCCESS:
      return { result: action.result, error: null, fetching: false };
    case actions.POST_CREATE_ORDER_ERROR:
      return { result: [], error: action.error, fetching: false };
    default:
      return state;
  }
};

export default combineReducers({
  info
});
